.add-signature-JoditRichEditor .jodit-container:not(.jodit_inline) {
  border: none !important
}
.add-signature-JoditRichEditor .jodit-toolbar__box:not(:empty):not(:empty) {
  border: none !important
}
.add-signature-JoditRichEditor textarea {
  display: none;
}
.add-signature-JoditRichEditor-readOnly .jodit-toolbar__box:not(:empty) .jodit-toolbar-editor-collection:after {
  background-color: rgb(167, 175, 190) !important;
}
.add-signature-JoditRichEditor .jodit-toolbar-editor-collection_mode_horizontal:after {
  height: 0;
}
.add-signature-JoditRichEditor-readOnly .jodit-container:not(.jodit_inline) {
  border: none !important
}
.add-signature-JoditRichEditor-readOnly .jodit-toolbar__box:not(:empty):not(:empty) {
  border: none !important
}
.add-signature-JoditRichEditor-readOnly textarea {
  display: none;
}
.add-signature-JoditRichEditor-readOnly .jodit-toolbar__box:not(:empty) .jodit-toolbar-editor-collection:after {
  background-color: rgb(167, 175, 190) !important;
}
.add-signature-JoditRichEditor .jodit-container:not(.jodit_inline) {
  background-color: unset !important;
}
.add-signature-JoditRichEditor-readOnly .jodit-toolbar-editor-collection_mode_horizontal:after {
  height: 0;
}
.add-signature-JoditRichEditor-readOnly .jodit-wysiwyg,
.add-signature-JoditRichEditor-readOnly .jodit-container,
.add-signature-JoditRichEditor-readOnly .jodit-workplace {
  min-height: unset !important;
  height: unset !important;
}
.add-signature-JoditRichEditor-readOnly .jodit-container:not(.jodit_inline) {
 padding-bottom: 0;
}

