

.content-wrapper
{
  display: flex;
  flex: 1;
}

.sidebar {
  width: 300px;
  overflow-y: auto;
  border-right: 1px solid #e0e0e0;
  background-color: #fff;
}

.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #fff;
}

.header {
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 20px;
}

.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  font-size: 24px;
  margin: 0;
}

.status {
  background-color: #ffd700;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
}

.actions {
  display: flex;
  gap: 10px;
}

.btn-icon {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.visit-info {
  display: flex;
  gap: 20px;
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.sidebar-section h2 {
  font-size: 16px;
  margin-bottom: 10px;
  padding: 0 15px;
}

.sidebar-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-left: 3px solid transparent;
  cursor: pointer;
}

.sidebar-item:hover {
  background-color: #f0f0f0;
}

.sidebar-item.completed {
  border-left-color: #4CAF50;
}

.sidebar-item.skipped {
  border-left-color: #FFA500;
}

.sidebar-item.in-progress {
  border-left-color: #2196F3;
}

.item-name {
  flex-grow: 1;
}

.item-date {
  font-size: 12px;
  color: #666;
}

.item-status-icon {
  margin-left: 10px;
  font-size: 18px;
}

.main-content h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.form-meta {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.actions {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.actions button {
  padding: 8px 16px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.actions button.active {
  background-color: #e6f7ff;
  border-color: #1890ff;
  color: #1890ff;
}

.adl-form fieldset {
  border: none;
  margin-bottom: 20px;
  padding: 0;
}

.adl-form legend {
  font-weight: bold;
  margin-bottom: 10px;
}

.adl-form .options {
  display: flex;
  gap: 20px;
}

.adl-form label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.adl-form input[type="radio"] {
  margin: 0;
}

.main-content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}


.divider-bottom {
  border-bottom: 1px solid #D0D6E1;
}

.key-points-view {
  background-color: #F6F7F8;
  color: #6F7A90;
  padding: 4px 10px;
}

.main-content-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.sidebar-view {
  flex: 0.25;
  border-right: 1px solid #D0D6E1;
  user-select: none;
}

.main-content-view {
  flex: 0.75;
  padding: 20px;
}

.sidebar-item-collapsible {
  cursor: pointer;
  border-bottom: 1px solid #D0D6E1;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  padding: 8px;

  span {
    height: 100%;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter_18pt-Medium';
    color: #3A485F;
  }
}
.sidebar-step-content-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;  
  padding-left: 10px;
  justify-content: space-between;
}

.step-title {
  margin-right: 4px;
}

.sidebar-item-child {
  padding: 8px 12px;
  font-weight: 400;
  font-size: 14px;
}

.active-step {
  background-color: #FCFAFF;
  color: #8C5AE2;
  font-weight: 400;
  font-size: 14px;
  position: relative;
}

// indicator for active step at center of the sidebar item height
.active-step-indicator {
  left: 0;
  height: 90%;
  background-color: #8C5AE2;
  border-radius: 2px;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-pointer {
  cursor: pointer;
  padding: 2px;
  border-radius: 4px;
  &:hover {
    background-color: #F6F7F8;
  }
}

.content-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
}

.cm-action-popover .ant-popover-inner-content {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.add-assessment-drawer {
  .ant-drawer-body {
    padding-top: 16px;
  }

  .ant-drawer-header {
    padding-bottom: 0;
  }
}

.header-view {
  padding: 4px 12px;
}

.rotate-90-cw {
	-webkit-animation: rotate-90-cw 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: rotate-90-cw 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}
@keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-11-14 15:21:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-90-ccw
 * ----------------------------------------
 */
 @-webkit-keyframes rotate-90-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }100% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  }
  
  .rotate-90-ccw {
    -webkit-animation: rotate-90-ccw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: rotate-90-ccw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  
  @keyframes rotate-90-ccw {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
}

.step-title {
  text-transform: capitalize;
  color: #3A485F;
}

.content-header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  padding: 0 12px;

  .content-header-title {
    font-size: 14px;
    font-weight: 500;
    color: #3A485F;
    font-family: 'Inter_18pt-Medium';
    text-transform: capitalize;
  }
}

.cadence-popover .ant-popover-inner-content {
    padding: 0 !important;
}

.no-padding-input .ant-input-number-input {
  height: 24px !important;
}