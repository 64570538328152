.custom-text-input {
  border-radius: 4px;
  border-color: #e5e5e5;
  padding: 8px;
  resize: vertical;
  color: #3A485F !important;
}

.custom-text-input::-webkit-scrollbar {
  width: 14px;
  height: 0px;
}

.custom-text-input::placeholder {
  color: #8A94A8 !important;
}

.custom-text-input-error {
  border-color: #d92d20;
}

.custom-text-input:hover {
  background-color: #f4f4f5;
}

