.ant-layout-content.messagewindow-editor-task-comment-customized .toastui-editor-toolbar-group>.toastui-editor-toolbar-icons {
  margin: 0;
  padding: 0;
  align-self: flex-end;
  justify-content: center;
  align-items: flex-end;
  align-self: flex-end;
  justify-items: center;
  justify-self: flex-end;
  margin-left: -4px !important;
}

.ant-layout-content.messagewindow-editor-task-comment-customized .toastui-editor-toolbar-group {
  margin-left: -16px !important;
}

.ant-layout-content.messagewindow-editor-task-comment-customized .toastui-editor-defaultUI-toolbar {
  background-color: #ffffff !important;
  border-bottom: 1px solid #d0d5dd !important;
}

.ant-layout-content.messagewindow-editor-task-comment-customized .toastui-editor-toolbar {
  height: 46px !important;
  border: 1px solid #d0d5dd !important;
  border-bottom: none !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.ant-layout-content.messagewindow-editor-task-comment-customized .toastui-editor-defaultUI {
  border: none !important;
}

.ant-layout-content.messagewindow-editor-task-comment-customized .ProseMirror.toastui-editor-contents {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  height: 85px !important;
  border: 1px solid #d0d5dd !important;
  border-top: none !important;
  font-size: 15px;
}

.ant-layout-content.messagewindow-editor-task-comment-error-customized .toastui-editor-toolbar-group>.toastui-editor-toolbar-icons {
  margin: 0;
  padding: 0;
  align-self: flex-end;
  justify-content: center;
  align-items: flex-end;
  align-self: flex-end;
  justify-items: center;
  justify-self: flex-end;
  margin-left: -4px !important;
}

.ant-layout-content.messagewindow-editor-task-comment-error-customized .toastui-editor-toolbar-group {
  margin-left: -16px !important;
}

.ant-layout-content.messagewindow-editor-task-comment-error-customized .toastui-editor-defaultUI-toolbar {
  background-color: #ffffff !important;
  border: 1px solid #d0d5dd !important;
}

.ant-layout-content.messagewindow-editor-task-comment-error-customized .toastui-editor-toolbar {
  height: 46px !important;
  border: 1px solid #d0d5dd !important;
  border-bottom: none !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.ant-layout-content.messagewindow-editor-task-comment-error-customized .toastui-editor-defaultUI {
  border: none !important;
}

.ant-layout-content.messagewindow-editor-task-comment-error-customized .ProseMirror.toastui-editor-contents {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  height: 85px !important;
  border: 1px solid red !important;
  background-color: #fcfcfd !important;
  font-size: 15px;
}

.ant-layout-content.messagewindow-editor-task-comment-singleline-customized .ProseMirror.toastui-editor-contents {
  border: 1px solid #d0d5dd !important;
  border-radius: 4px !important;
  height: 48px !important;
  font-size: 15px;
}

.ant-layout-content.messagewindow-editor-task-comment-singleline-customized .toastui-editor-toolbar-group {
  display: none !important;
}

.ant-layout-content.messagewindow-editor-task-comment-singleline-customized .toastui-editor-defaultUI .toastui-editor-toolbar {
  border: none !important;
  height: 0px !important;
}

.ant-layout-content.messagewindow-editor-task-comment-singleline-customized  {
 height: 60px !important;
}

.ant-layout-content.messagewindow-editor-task-comment-singleline-customized .toastui-editor-defaultUI-toolbar {
  border-bottom: none !important
}
