:root {
  --tertiary-50: #ecfdf5;
  --tertiary-100: #d1fae5;
  --tertiary-200: #a7f3d0;
  --tertiary-300: #6ee7b7;
  --tertiary-400: #34d399;
  --tertiary-500: #10b981;
  --tertiary-600: #059669;
  --tertiary-700: #047857;
  --tertiary-800: #065f46;
  --tertiary-900: #064e3b;

  --secondary-50: #fdf2f8;
  --secondary-100: #fce7f3;
  --secondary-200: #fbcfe8;
  --secondary-300: #f9a8d4;
  --secondary-400: #f472b6;
  --secondary-500: #ec4899;
  --secondary-600: #db2777;
  --secondary-700: #be185d;
  --secondary-800: #9d174d;
  --secondary-900: #831843;

  --muted-50: #fafafa;
  --muted-100: #f5f5f5;
  --muted-200: #e5e5e5;
  --muted-300: #d4d4d4;
  --muted-400: #a3a3a3;
  --muted-500: #737373;
  --muted-600: #525252;
  --muted-700: #404040;
  --muted-800: #262626;
  --muted-900: #171717;

  --error-50: #fef2f2;
  --error-100: #fee2e2;
  --error-200: #fecaca;
  --error-300: #fca5a5;
  --error-400: #f87171;
  --error-500: #ef4444;
  --error-600: #dc2626;
  --error-700: #b91c1c;
  --error-800: #991b1b;
  --error-900: #7f1d1d;

  --success-50: #f0fdf4;
  --success-100: #dcfce7;
  --success-200: #bbf7d0;
  --success-300: #86efac;
  --success-400: #4ade80;
  --success-500: #22c55e;
  --success-600: #16a34a;
  --success-700: #15803d;
  --success-800: #166534;
  --success-900: #14532d;

  --primary-50: #ecfeff;
  --primary-100: #cffafe;
  --primary-200: #a5f3fc;
  --primary-300: #67e8f9;
  --primary-400: #22d3ee;
  --primary-500: #06b6d4;
  --primary-600: #0891b2;
  --primary-700: #0e7490;
  --primary-800: #155e75;
  --primary-900: #164e63;

  --warning-50: #fff7ed;
  --warning-100: #ffedd5;
  --warning-200: #fed7aa;
  --warning-300: #fdba74;
  --warning-400: #fb923c;
  --warning-500: #f97316;
  --warning-600: #ea580c;
  --warning-700: #c2410c;
  --warning-800: #9a3412;
  --warning-900: #7c2d12;

  --danger-50: #fff1f2;
  --danger-100: #ffe4e6;
  --danger-200: #fecdd3;
  --danger-300: #fda4af;
  --danger-400: #fb7185;
  --danger-500: #f43f5e;
  --danger-600: #e11d48;
  --danger-700: #be123c;
  --danger-800: #9f1239;
  --danger-900: #881337;

  --light-50: #fafaf9;
  --light-100: #f5f5f4;
  --light-200: #e7e5e4;
  --light-300: #d6d3d1;
  --light-400: #a8a29e;
  --light-500: #78716c;
  --light-600: #57534e;
  --light-700: #44403c;
  --light-800: #292524;
  --light-900: #1c1917;

  --info-50: #f0f9ff;
  --info-100: #e0f2fe;
  --info-200: #bae6fd;
  --info-300: #7dd3fc;
  --info-400: #38bdf8;
  --info-500: #0ea5e9;
  --info-600: #0284c7;
  --info-700: #0369a1;
  --info-800: #075985;
  --info-900: #0c4a6e;
}
