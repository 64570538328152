.ant-timeline-item-head {
  background-color: #0000;
}

.ant-picker {
  background-color: white;
}

.fhir-container__ResourceContainer__json-button-wrapper {
  display: none;
}