.custom-drawer .ant-drawer-header {
  padding: 0px;
}

.custom-drawer .ant-drawer-body {
  padding: 0px;
}

.update-Signature-Info .ant-popover-arrow {
  display: none;
}

.disable-Email-signature-settings {
  pointer-events: none;
  opacity: 0.5;
}

.email-inbox-preference {
  pointer-events: none;
  opacity: 0.5;
}

.delete-signature .ant-modal-header {
  padding: 0;
}

.delete-signature .ant-modal-body {
  padding: 0 !important;
}

.delete-signature .ant-modal-footer {
  padding: 0 !important;
}

.delete-signature .ant-modal-content {
  padding: 20px !important;
}

.auto-reply-close-modal .ant-modal-footer {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 20px !important;
}