.required-dot {
  width: 3px;
  height: 3px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  margin-top: 3px;
}

.custom-form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 44px;
}
.select-custom-field.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 44px !important;
  line-height: 44px !important;
}
.select-custom-field .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
}

.select-custom-field .ant-select-item-option {
  margin : 0px;
  padding: 0px
}

.select-custom-field.ant-select-dropdown {
  border-radius: 6px !important;
}

.addCustomFieldDrawer .ant-drawer-content {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.custom-form .ant-form-item {
  margin-bottom: 16px;
}

.custom-switch {
  width: 44px;
  height: 22px;
  background-color: transparent;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
}

.custom-switch-handle {
  width: 18px;
  height: 18px;
  background-color: #D0D5DD;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
  align-self: center;
  transform: translateX(2px) translateY(1px);
}

.custom-switch-handle.checked {
  background-color: #825AC7;
}

.custom-switch.checked .custom-switch-handle {
  transform: translateX(24px) translateY(1px);
}

.select-custom-field.ant-popover-arrow {
  display: none;
}

.custom-form.ant-form-vertical .ant-form-item-label > label {
  color: '#8A94A8';
  font-weight: 500;
  font-size: 14;
}