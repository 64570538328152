.lead-list-table-view.patient-list-view .ant-checkbox-wrapper .ant-checkbox {
  position: relative;
}

.lead-list-table-view.patient-list-view
  .ant-checkbox-wrapper
  .ant-checkbox-input {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.lead-list-table-view.patient-list-view
  .ant-table-tbody
  > tr.ant-table-row-selected
  > td {
  background-color: #fff;
}
.lead-list-table-view.patient-list-view
  .ant-table-tbody
  tr.table-row-light
  > td {
  background-color: #f9fafb;
}
.lead-list-table-view.patient-list-view
  .ant-pagination.ant-table-pagination.ant-table-pagination-center
  .ant-pagination-item {
  border-width: 0;
}
.lead-list-table-view.patient-list-view
  .ant-pagination.ant-table-pagination.ant-table-pagination-center
  .ant-pagination-item.ant-pagination-item-active {
  background-color: #825ac7;
  border-radius: 4px;
  color: #fff;
}
.lead-list-table-view.patient-list-view
  .ant-pagination.ant-table-pagination.ant-table-pagination-center
  .ant-pagination-prev
  button,
.lead-list-table-view.patient-list-view
  .ant-pagination.ant-table-pagination.ant-table-pagination-center
  .ant-pagination-next
  button {
  border-width: 0;
}

.lead-list-table-view.patient-list-view .ant-table-tbody tr.ant-table-row-level-0 td.ant-table-cell-row-hover {
  background-color: #fff !important;
}
.lead-list-table-view.patient-list-view .ant-table-filter-column .ant-table-filter-trigger {
  margin-right: 10px;
  }
/* .ant-table-filter-dropdown > ul li:first-child {
  font-weight: 600;
pointer-events: none;
} */
.ant-table-filter-dropdown  ul li:first-child  .ant-dropdown-menu-title-content .ant-radio-wrapper{
  display: none;
  visibility: hidden;
}
.ant-table-filter-dropdown > ul li.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #8c210f;
}
.ant-table-filter-dropdown > ul li:first-child  .ant-dropdown-menu-title-content .ant-radio-wrapper{
  display: none;
  visibility: hidden;
}
.ant-table-filter-dropdown  .ant-btn-link {
  color: #000000;
}
.ant-dropdown-menu-submenu .ant-dropdown-menu li:hover  {
  background-color: #f5f5f5;
}
.ant-dropdown-menu-submenu .ant-dropdown-menu li.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected {
  color: #8c210f;
  background-color: #f5f5f5;
}

.report-table td.ant-table-cell.ant-table-cell-fix-left,
.report-table td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-row-hover {
  background-color: #fff !important;
}

.report-table td.ant-table-cell.ant-table-cell-fix-left-last,
.report-table td.ant-table-cell.ant-table-cell-fix-left-last.ant-table-cell-row-hover {
  background-color: #f9fafb !important;
}
.ant-table-thead .ant-table-cell {
  background-color: #fff !important;
}
.report-table .ant-table-header,
.report-table .ant-table-thead .ant-table-cell {
  background-color: #f9fafb !important;
}

.ant-table-cell.ant-table-selection-column label{
  padding-left: 8px !important;
}
.workflow-table .ant-table-thead .ant-table-cell {
  padding: 8px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-wrap: normal;
}
