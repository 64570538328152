.left-container .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-collapse-content.ant-collapse-content-active{
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.left-container .ant-collapse-header {
  background-color: #fff;
}

.left-container .ant-collapse-extra {
  background-color: #fff;
}
.left-container .ant-collapse-content {
  border-top: 1px solid #e5e7eb;
}

.left-container .ant-collapse {
  border: 1px solid #e5e7eb;
}

.left-container .ant-collapse > .ant-collapse-item {
  border-bottom: none;
}

.pami-left-view  .ant-collapse > .ant-collapse-item {
  background: #f2f4f7;
}

.pami-left-view  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.pami-left-view  .ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header,
.ant-collapse-item.ant-collapse-item-active > .ant-collapse-header{
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.pami-left-view .ant-collapse-content.ant-collapse-content-active .ant-collapse-content-box .ant-collapse-item .ant-collapse-header,
.pami-left-view .ant-collapse-content.ant-collapse-content-active .ant-collapse-content-box .ant-collapse-item.ant-collapse-item-active.last-subheader .ant-collapse-header {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
} 

.pami-left-view .ant-collapse-content.ant-collapse-content-active .ant-collapse-content-box .ant-collapse-item.last-subheader .ant-collapse-header {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
} 

.pami-left-view .ant-collapse-content.ant-collapse-content-active .ant-collapse-content-box .ant-collapse-item.ant-collapse-item-active .ant-collapse-content-box {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background: #fff;
} 

.pami-left-view .ant-collapse-content.ant-collapse-content-active .ant-collapse-content-box .ant-collapse-item.ant-collapse-item-active.last-subheader .ant-collapse-content-box,
.pami-left-view .ant-collapse-content.ant-collapse-content-active .ant-collapse-content-box {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  background: #fff;
}
