.ant-layout-content.message-box-viewer div .toastui-editor-contents {
  font-size: 14px !important;
  font-family: 'Inter_18pt-Regular';
}

.ant-layout-content.appointment-task-message-box-viewer div .toastui-editor-contents p {
  font-size: 14px !important;
  font-family: 'Inter_18pt-Regular';
  margin-left: 5px !important;
  margin-right: 5px !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.ant-layout-content.message-box-viewer.message-box-font-color div,
.ant-layout-content.message-box-viewer.message-box-font-color p,
.ant-layout-content.message-box-viewer.message-box-font-color h1,
.ant-layout-content.message-box-viewer.message-box-font-color h2,
.ant-layout-content.message-box-viewer.message-box-font-color h3,
.ant-layout-content.message-box-viewer.message-box-font-color h4,
.ant-layout-content.message-box-viewer.message-box-font-color h5,
.ant-layout-content.message-box-viewer.message-box-font-color h6,
.ant-layout-content.message-box-viewer.message-box-font-color span,
.ant-layout-content.message-box-viewer.message-box-font-color a {
  /* color: #101828 !important; */
  color: #fff !important;
  font-weight: 500;
}
.ant-layout-content.message-box-viewer.message-box-font-color a {
  background: rgba(255, 255, 255, 0.1);
  padding: 4px !important;
  border-radius: 4px;
  margin: 0 !important;
  text-decoration: underline !important;
}

.ant-layout-content.message-box-viewer a{
  background: rgba(0, 0, 0, 0.05);
  padding: 4px !important;
  border-radius: 4px;
  margin: 0 !important;
  text-decoration: underline !important;
}

.ant-layout-content.link a{
  color: black !important;
  font-size: 14px !important;
  background: rgba(0, 0, 0, 0.05);
  padding: 4px !important;
  border-radius: 4px;
  margin: 0 !important;
  text-decoration: underline !important;
}

.ant-layout-content.link-message a{
  color: #475467 !important;
  font-size: 14px !important;
  font-weight: 400;
  background: rgba(0, 0, 0, 0.05);
  text-decoration: underline !important;
}

.ant-layout-content.link-message p{
  color: #475467 !important;
  font-size: 14px !important;
}

.ant-layout-content.link p{
  font-size: 14px !important;
}


