/* -------- Remove if you don't want popped view -------- */
.custom-drawer-sizing .ant-drawer-content-wrapper {
    padding: 8px !important;
  }
  .custom-drawer-sizing.ant-drawer-right > .ant-drawer-content-wrapper {
    box-shadow: none!important;
  }
  .custom-drawer-sizing.ant-drawer-right > .ant-drawer-content-wrapper {
    box-shadow: none!important;
  }
  .custom-drawer-sizing .ant-drawer-content {
    border-radius: 12px;
  }
  /* ---------------- */
  .custom-drawer-sizing .ant-drawer-header {
    padding: 24px !important;
  }
  .custom-drawer-sizing .ant-drawer-body {
    padding: 0px 24px !important;
    padding-bottom: 24px !important;
  }
  .custom-drawer-sizing.no-body-padding .ant-drawer-body {
    padding: 0px !important;
  }
  .custom-drawer-sizing.no-header-padding .ant-drawer-header {
    padding: 0px !important;
  }
