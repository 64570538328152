.zero-padding-popover {
  .ant-popover-inner {
    padding: 0px !important;
    border-radius: 8px !important;
  }
  .ant-popover-inner-content {
    padding: 0px !important;
    width: 200px !important;
  }
}
