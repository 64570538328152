.customMsgTabs .ant-tabs-nav-list .ant-tabs-tab {
  padding: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: 0px;
  border-radius: 20px;
  margin-bottom: 16px;
}
.customMsgTabs .ant-tabs-nav {
  padding-left: 0 !important;
  margin-top: 16px;
}
.customMsgTabs .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  color: #667085;
}
.customMsgTabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #825ac7;
}
.customMsgTabs
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}
.customMsgTabs .ant-tabs-nav-list .ant-tabs-ink-bar-animated {
  display: none;
}
.customMsgTabs .ant-tabs-nav .ant-tabs-nav-wrap {
  /*
    This line is commented because we have enabled internal
    message tab due to which tab goes to right side.
  */

  /* justify-content: center; */
}

.customTabPane {
  background-color: pink;
}
.ant-tabs-nav {
  padding-left: 12px !important;
}

.popover-class {
  visibility: hidden;
}

.ant-picker-now-btn {
  display: none;
}

.ant-picker-ok {
  display: none;
}
