.day-op.animate-scheduler {
  transition: width 0.3s ease-in-out;
}

.day-op .rbc-time-header.rbc-overflowing {
  display: none !important;
}

.day-op .rbc-time-content {
  border: 0 !important;
}

.day-op .rbc-time-slot .rbc-label {
  padding: 0 !important;
  padding-right: 12px !important;
}

.table-row-dayOptimizer {
  background-color: white !important;
}

.table-row-dayOptimizer:hover {
    background-color: #F6F7F8 !important;
    cursor: pointer;
}

.threeDotsPopoverStyle .ant-popover-inner-content {
  padding: 0 !important;
  /* background-color: #F6F7F8 !important; */
}

.userSelectionDropDownPopoverStyle .ant-popover-inner-content {
  padding: 8px 12px !important;
  width: 280px !important;
}

.day-op.patient-search-listing .ant-table-thead .ant-table-cell {
  padding: 0 16px !important;
  padding-bottom: 8px !important;
}

.day-op.patient-search-listing .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none !important;
}

.day-op.patient-search-listing .ant-table-column-title {
  flex: 0 !important;
}
.ant-table-column-title, .ant-table-thead > tr > th {
  font-size: 14px !important;
  color: #6F7A90;
  font-family: 'Inter_18pt-Medium';
}

.day-op.patient-search-listing .ant-table-column-sorters {
  justify-content: flex-start !important;
}

.threeDotsPopoverStyle .ant-popover-inner-content {
  padding: 0 !important;
  min-width: 240px !important;
}

.filter-popover-style .ant-popover-inner-content {
  padding: 0 !important;
  min-width: 200px !important;
}

.day-optimizer-calendar .ant-picker-cell-in-view.ant-picker-cell .ant-picker-cell-inner,
.day-optimizer-calendar .ant-picker-cell-in-view.ant-picker-cell .ant-picker-cell-inner::before,
.day-optimizer-calendar .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.day-optimizer-calendar .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  border-radius: 50px;
}

.day-optimizer-calendar .ant-picker-cell-in-view.ant-picker-cell:not(.ant-picker-cell-today) .ant-picker-cell-inner::before {
  border: none !important;
}

.day-optimizer-patient-table {
  .ant-table-footer {
    padding: 0 !important; /* Remove padding */
  }  
}

.day-op .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #F6F7F8 !important;
}

.day-optimizer-hide-patient-table {
  display: 'none';
}