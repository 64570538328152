.patient-document-upload-container .ant-upload-list-item-info {
  cursor: pointer;
}

.patient-document-upload-container .ant-upload-list-item-thumbnail {
  height: auto;
}

.patient-document-upload-container .ant-upload {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  height: 50px;
  border-radius: 5px;
  background-color: #FEEDD5;
}

.patient-document-upload-container {
  border-style: dashed;
  border-color: #FDD7AD;
}
