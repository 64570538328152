@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/sass/time-column';

$event-border-radius: 8px;
$date-border-radius: 10px;
$availability-event-border-radius: 6px;

// .rbc-event,
// .rbc-day-slot .rbc-background-event {
//   background-color: var(--primary-100);
//   color: var(--primary-500);
//   font-size: 0.7rem;
//   border: 1px solid var(--primary-500);
//   border-left: 4px solid var(--primary-500);
// }

.rbc-event {
  z-index: 2;
}

// .rbc-day-slot .rbc-events-container {
//   margin-right: 0px;
// }

// .rbc-day-slot .rbc-event,
// .rbc-day-slot .rbc-background-event {
//   border: 1px solid var(--primary-500);
//   border-left: 4px solid var(--primary-500);
//   background-color: hsla(228, 65%, 92%, 1);
//   border-radius: 3px;
//   padding: 2px;
// }

.dashboard-event-VIRTUAL {
  background-color: var(--info-100) !important;
  color: var(--info-500) !important;
  border:1px solid rgba(14, 165, 233, 0.1) !important;
  border-left: 4px solid var(--info-500) !important;
}

.dashboard-event-CLINIC {
  background-color: #F2EDFF !important;
  color: #6941C7 !important;
  border:1px solid rgba(127, 17, 224, 0.1) !important;
  border-left: 4px solid #6941C6 !important;
}

.dashboard-month-event .block-interval {
  border-radius: 5px;
  padding-left: 0.1em;
}
.dashboard-month-event .block-interval,
.dashboard-event- {
  margin:0.5px !important;
  background-color: var(--secondary-100) !important;
  color: var(--secondary-500) !important;
  border: 1px solid var(--secondary-500) !important;
  border-left: 4px solid var(--secondary-500) !important;
}

.dashboard-month-event {
  background-color: transparent !important;
  border: none;
  :focus{
    outline: none;
  }
}

.dashboard-event-status-PENDING {
  background-color: white !important;
  border: 1px dashed  !important;
}
.dashboard-event-status-BOOKED {
  color: #6941C7 !important;
}
.dashboard-event-status-BOOKED.dashboard-event-status-VIRTUAL {
  color:#6941C7 !important;
}
.dashboard-event-status-show-more {
  background-color: var(--light-0) !important;
  border: var(--light-0) !important;
}

.dashboard-event-status-CANCELLED {
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  opacity: 0.5;
}

.dashboard-event-status-DECLINED {
  opacity: 0.5;
  background-color: var(--light-200) !important;
}

.dashboard-event-status-PROPOSED {
  background-color: var(--warning-100) !important;
  border-left: 4px dotted !important;
}

.rbc-allday-cell {
  display: none;
}
.rbc-time-view .rbc-header {
  border-bottom: none;
}

.rbc-show-more {
  color: var(--primary-500);
}

.rbc-show-more:hover {
  color: var(--primary-600);
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: var(--primary-100);
}

.rbc-timeslot-group {
  min-height: 160px;
}
.rbc-time-gutter .rbc-timeslot-group {
  font-size: 12px;
  color: var(--light-600);
  border-bottom: none;
  width: 60px;
}

.rbc-time-header-gutter {
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important;
}

.rbc-off-range-bg {
  background: var(--light-50);
}

.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid var(--muted-200);
}

.rbc-event-label {
  display: none;
}
.rbc-today {
  background-color: rgba(130, 90, 199, 0.125) !important;
}
.rbc-current-time-indicator {
  height: 1.5px;
  background-color: var(--error-500) !important;
}

.rbc-time-gutter {
  width: 60px;
}

.rbc-time-header-content {
  .rbc-row-resource {
    .rbc-header{
      div {
        color: #6941C7 !important;
        font-weight: 600 !important;
      }
    }
  }
  .rbc-time-header-cell {
      .rbc-header{
        cursor: default;
        & > button {
          cursor: default;
        }
        border:none !important;
        box-sizing: border-box !important;
        border-right: 0.5px solid #ddd !important;
        border-left: 0.5px solid #ddd !important;
        border-top: 1px solid #ddd !important;
      }
    .rbc-today {
      border-top: 5px solid #6941C7 !important;
    }
  }
}

.rbc-row {
  .rbc-today {
    border-top: 5px solid #6941C7 !important;
  }
}



.rbc-time-header.rbc-overflowing {
  border: none;
  // margin-bottom: 0.5em;
}
.rbc-time-header-content > .rbc-row.rbc-row-resource {
  padding: 10px
}
.calendar-drawer-day-view {
  $header-width: 200px;
  $column-offset: 20px;
  .rbc-timeslot-group,
  .rbc-time-content,
  .rbc-time-content > * + * > *,
  .rbc-time-content > .rbc-time-gutter,
  .rbc-label.rbc-time-header-gutter,
  .rbc-time-header .rbc-time-header-content {
    border: none;
  }

  .rbc-time-header {
    overflow-x: auto;
    .rbc-time-header-content {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid hsl(218, 17%, 91%);
      padding: 0.5em 1em;
      border-radius: $date-border-radius;
      margin: 10px;
      width: $header-width;
      min-width: unset;
      text-transform: capitalize;
      // flex:none;
      .rbc-row-resource {
        & .rbc-header {
          & > div {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
  }

  .rbc-day-slot .rbc-events-container {
    .rbc-event {
      padding: 5px;
    }
  }

  .rbc-time-content {
    padding-top: 1em;
    .rbc-time-column {
      & .rbc-timeslot-group {
        min-height: 160px;
      }
    }
  }
  .rbc-time-header {
    display: flex;
    justify-content: space-between;
    .rbc-time-header-content {
      // display:flex;
      // align-items: center;
      // justify-content: center;
      // border: none;
      // padding: unset;
      // border-radius:unset;
      // width: 9%;
      // min-width: unset;
      // flex:none;
      .rbc-row-resource {
      }
    }
  }
  .rbc-time-view-resources .rbc-time-content .rbc-day-slot {
    &.rbc-time-column {
      //  margin-left:10px;
      min-width: unset;
      background-color: white;
      // width:9%;
    }
    // width: calc(($header-cell-width - ($today-day-width)) / ($week-days - 1));
    &.rbc-today {
      // width: $today-day-width;
      background-color: transparent;
    }
    & .dashboard-event-.dashboard-event-status-BOOKED {
      .rbc-event-label {
        display: none;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        // color: hsla(218, 15%, 65%, 1);
        color: var(--secondary-500);
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
      }
      div:nth-child(2) {
        font-size: 11px;
        font-weight: 400;
      }
    }
  }

  .rbc-time-view-resources .rbc-time-content .rbc-day-slot {
    .rbc-timeslot-group {
      .rbc-time-slot {
        border-color: #d0d5dd;
      }
      .rbc-time-slot:nth-child(2) {
        border-top-style: dashed;
      }
    }
  }
  & .dashboard-event-VIRTUAL {
    background-color: transparent;
    border: none;
    .rbc-event-content {
      border-radius: $event-border-radius;
      padding: 0.5em;
      background-color: #f6ebfe;
      border: 1px solid #dcc8f5;
      overflow: hidden;
    }
    &.dashboard-event-status-BOOKED,
    &.dashboard-event-status-NOSHOW{
      .rbc-event-content {
        border-radius: $event-border-radius;
        padding: 0.5em;
        background-color: #f6ebfe;
        border: 1px solid #dcc8f5;
        overflow: hidden;
      }
    }
    &.dashboard-event-status-PENDING {
      .rbc-event-content {
        border-radius: $event-border-radius;
        padding: 0.5em;
        background-color: #f6ebfe;
        border: 1px dashed hsla(232, 36%, 72%, 1);
        overflow: hidden;
      }
    }
    &.dashboard-event-status-CANCELLED,
    &.dashboard-event-status-DECLINED {
      .rbc-event-content {
        border-radius: $event-border-radius;
        padding: 0.5em;
        background: #fcfdfd;
        border: 1px solid #fecdd3;
        overflow: hidden;
      }
    }
  }

  & .dashboard-event-CLINIC {
    background-color: transparent;
    border: none;
    &.dashboard-event-status-PENDING {
      .rbc-event-content {
        border-radius: $event-border-radius;
        background-color: rgba(222, 227, 248, 1);
        padding: 0.5em;
        border: 1px dashed hsla(232, 36%, 72%, 1);
        overflow: hidden;
      }
    }
    &.dashboard-event-status-BOOKED,
    &.dashboard-event-status-NOSHOW {
      .rbc-event-content {
        border-radius: $event-border-radius;
        padding: 0.5em;
        background-color: #6941C7 !important;
        border: 1px solid hsl(231, 66%, 85%);
      }
      div {
        color: hsl(232, 36%, 38%);
        overflow: hidden;
      }
    }

    &.dashboard-event-status-CANCELLED,
    &.dashboard-event-status-DECLINED {
      .rbc-event-content {
        border-radius: $event-border-radius;
        background: #fcfdfd;
        padding: 0.5em;
        border: 1px solid #fecdd3;
        overflow: hidden;
      }
    }
  }
  //FUTURE USE
  // .rbc-time-header-cell {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;
  //   .rbc-header {
  //     flex: none;
  //     border: none;
  //     border: 1px solid hsl(218, 17%, 91%);
  //     height: 70px;
  //     padding: 0.5em 1em;
  //     border-radius: $date-border-radius;

  //     &.rbc-today {
  //       background-color: hsl(273, 82%, 98%);
  //       border-bottom: 5px solid hsla(261, 52%, 37%, 1);
  //       border-radius: $date-border-radius;
  //       & > a {
  //         & > div {
  //           div {
  //             text-transform: capitalize;
  //             color: hsl(262, 49%, 57%);
  //           }
  //           div:nth-child(2) {
  //             font-weight: 600;
  //           }
  //         }
  //       }
  //     }

  //     & > button {
  //       & > div {
  //         display: flex;
  //         flex-direction: row-reverse;
  //         align-items: center;
  //         justify-content: flex-end;
  //         gap: 1em;
  //         div {
  //           text-transform: capitalize;
  //         }
  //       }
  //       // &, &:active, &:visited {
  //       //   color: inherit;
  //       //   text-decoration: none;
  //       // }
  //     }
  //   }
  // }
  & .dashboard-event- {
    background-color: transparent;
    border: none;
    .rbc-event-content {
      // background: hsla(218, 17%, 91%, 1);
      // background-image: radial-gradient(
      //   hsla(217, 16%, 84%, 1) 1px,
      //   transparent 0
      // );
      // background-size: 10px 10px;
      // border: 1px solid hsl(218, 17%, 91%);
      // border-radius: 8px;
      background-color: var(--secondary-100);
      color: var(--secondary-500);
      border: 1px solid var(--secondary-500);
      // border-left: 4px solid var(--secondary-500);
      border-radius: 8px;
    }
    // background-position: -19px -19px;
  }
  &.fixed-width-day-view {
    .rbc-time-header {
      // display: flex;
      // justify-content: space-between;
      overflow-x: auto;
      .rbc-time-header-content {
        width: $header-width;
        min-width: unset;
        flex: none;
      }
    }
    .rbc-time-view-resources .rbc-time-content .rbc-day-slot {
      &.rbc-time-column {
        // width:9%;
        min-width: calc($header-width + $column-offset);
        // min-width: unset;
      }
    }
  }
}
.calendar-day-view {
  $header-width: 200px;
  $column-offset: 20px;

  .rbc-timeslot-group,
  .rbc-time-content,
  .rbc-time-content > * + * > *,
  .rbc-time-content > .rbc-time-gutter,
  .rbc-label.rbc-time-header-gutter,
  .rbc-time-header .rbc-time-header-content {
    border: none;
  }
  .rbc-time-view .rbc-header {
    border-bottom: none;
    width: fit-content;
  }
  .rbc-day-slot .rbc-events-container {
    // margin-right: 0px;
    .rbc-event {
      padding: 4.5px;
      border-radius: $event-border-radius;
    }

    & .dashboard-event-VIRTUAL {
      background-color: transparent;
      border: none;
      .rbc-event-content {
        border-radius: $event-border-radius;
        padding: 0.5em;
        background-color: #f6ebfe;
        border: 1px solid #dcc8f5;
        overflow: hidden;
      }
      &.dashboard-event-status-BOOKED,
      &.dashboard-event-status-NOSHOW {
        .rbc-event-content {
          border-radius: $event-border-radius;
          padding: 0.5em;
          background-color: #f6ebfe;
          border: 1px solid #dcc8f5;
          overflow: hidden;
        }
      }
      &.dashboard-event-status-PENDING {
        .rbc-event-content {
          border-radius: $event-border-radius;
          padding: 0.5em;
          background-color: #f6ebfe;
          border: 1px dashed hsla(232, 36%, 72%, 1);
          overflow: hidden;
        }
      }
      &.dashboard-event-status-CANCELLED,
      &.dashboard-event-status-DECLINED {
        .rbc-event-content {
          border-radius: $event-border-radius;
          padding: 0.5em;
          background: #fcfdfd;
          border: 1px solid #fecdd3;
          overflow: hidden;
        }
      }
      &.dashboard-event-.dashboard-event-status-BOOKED {
      }
      // div {
      //   color: hsl(261, 52%, 37%);
      //   font-weight: 500;
      //   overflow-wrap: anywhere;
      //   overflow-y: auto;
      // }
    }
    & .dashboard-event-CLINIC {
      background-color: transparent;
      border: none;
      &.dashboard-event-status-PENDING {
        .rbc-event-content {
          border-radius: $event-border-radius;
          background-color: rgba(222, 227, 248, 1);
          padding: 0.5em;
          border: 1px dashed hsla(232, 36%, 72%, 1);
          overflow: hidden;
        }
      }

      &.dashboard-event-status-CANCELLED,
      &.dashboard-event-status-DECLINED {
        .rbc-event-content {
          border-radius: $event-border-radius;
          background: #fcfdfd;
          padding: 0.5em;
          border: 1px solid #fecdd3;
          overflow: hidden;
        }
      }
    }

    & .dashboard-event-CLINIC.dashboard-event-status-BOOKED,
    .dashboard-event-CLINIC.dashboard-event-status-NOSHOW {
      //REQUIRED FOR FUTURE
      // background-color: hsla(228, 65%, 92%, 0.5);
      // border: 1px solid hsl(231, 66%, 85%);
      .rbc-event-content {
        border-radius: $event-border-radius;
        padding: 0.5em;
        background-color: #6941C7;
        border: 1px solid hsl(231, 66%, 85%);
        overflow: hidden;
      }
      div {
        color: hsl(232, 36%, 38%);
      }
    }

    //FUTURE USE
    // & .dashboard-event-.dashboard-event-status-BOOKED {
    //   div {
    //     color: hsla(218, 15%, 65%, 1);
    //   }
    // }

    & .dashboard-event-.dashboard-event-status-BOOKED {
      .rbc-event-label {
        display: none;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        // color: hsla(218, 15%, 65%, 1);
        color: var(--secondary-500);
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
      }
      div:nth-child(2) {
        font-size: 11px;
        font-weight: 400;
      }
    }
  }

  .rbc-time-content {
    padding-top: 1em;
    overflow-x: auto;
    .rbc-time-column {
      & .rbc-timeslot-group {
        min-height: 160px;
      }
    }
  }
  .rbc-time-header {
    overflow-x: auto;
    .rbc-time-header-content {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid hsl(218, 17%, 91%);
      padding: 0.5em 1em;
      border-radius: $date-border-radius;
      margin: 10px;
      width: $header-width;
      min-width: unset;
      text-transform: capitalize;
      // flex:none;
      .rbc-row-resource {
        & .rbc-header {
          & > div {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
  }
  .rbc-time-view-resources .rbc-time-content .rbc-day-slot {
    .rbc-timeslot-group {
      .rbc-time-slot {
        border-color: #d0d5dd;
      }
      .rbc-time-slot:nth-child(2) {
        border-top-style: dashed;
      }
    }
    &.rbc-time-column {
      // width:9%;
      // min-width:calc($header-width + $column-offset);
      // min-width: unset;
    }
    // width: calc(($header-cell-width - ($today-day-width)) / ($week-days - 1));
    // &.rbc-today {
    //   // width: $today-day-width;
    //   background-color: transparent;
    // }
  }

  // .rbc-time-header-cell {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;
  //   .rbc-header {
  //     flex: none;
  //     border: none;
  //     border: 1px solid hsl(218, 17%, 91%);
  //     height: 70px;
  //     padding: 0.5em 1em;
  //     border-radius: $date-border-radius;

  //     &.rbc-today {
  //       background-color: hsl(273, 82%, 98%);
  //       border-bottom: 5px solid hsla(261, 52%, 37%, 1);
  //       border-radius: $date-border-radius;
  //       & > a {
  //         & > div {
  //           div {
  //             text-transform: capitalize;
  //             color: hsl(262, 49%, 57%);
  //           }
  //           div:nth-child(2) {
  //             font-weight: 600;
  //           }
  //         }
  //       }
  //     }

  //     & > button {
  //       & > div {
  //         display: flex;
  //         flex-direction: row-reverse;
  //         align-items: center;
  //         justify-content: flex-end;
  //         gap: 1em;
  //         div {
  //           text-transform: capitalize;
  //         }
  //       }
  //       // &, &:active, &:visited {
  //       //   color: inherit;
  //       //   text-decoration: none;
  //       // }
  //     }
  //   }
  // }
  & .dashboard-event- {
    background-color: transparent;
    border: none;
    // background-position: -19px -19px;
    .rbc-event-content {
      //REQUIRED FOR FUTURE
      // background: hsla(218, 17%, 91%, 1);
      // background-image: radial-gradient(
      //   hsla(217, 16%, 84%, 1) 1px,
      //   transparent 0
      // );
      // background-size: 10px 10px;
      // border: 1px solid hsl(218, 17%, 91%);
      // border-radius: 8px;
      background-color: var(--secondary-100);
      color: var(--secondary-500);
      border: 1px solid var(--secondary-500);
      // border-left: 4px solid var(--secondary-500);
      border-radius: 8px;
      overflow: hidden;
    }
  }

  &.fixed-width-day-view {
    .rbc-time-header {

      overflow-x: auto;
      .rbc-time-header-content {
        width: $header-width;
        min-width: unset;
        flex: none;
      }
    }
    .rbc-time-view-resources .rbc-time-content .rbc-day-slot {
      &.rbc-time-column {
        min-width: calc($header-width + $column-offset);
      }
    }
  }
}

.calendar-week-view {
  $header-cell-width: 100%;
  $today-day-width: 20%;
  $offset: 4%;
  $week-days: 7;

  .rbc-timeslot-group,
  .rbc-time-content,
  .rbc-time-content > * + * > *,
  .rbc-time-content > .rbc-time-gutter,
  .rbc-label.rbc-time-header-gutter {
    border: none;
  }

  .rbc-time-content {
    padding-top: 1em;
    .rbc-time-column {
      & .rbc-timeslot-group {
        min-height: 160px;
      }
    }
  }
  .rbc-time-header-content {
    .rbc-header {
      div {
        text-transform: capitalize;
      }
    }
  }
  .rbc-time-header-cell {
    width: $header-cell-width;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .rbc-header {
      min-width: unset;
      width: calc(
        ($header-cell-width - ($today-day-width + $offset)) / ($week-days - 1)
      );
      flex: none;
      border: none;
      border: 1px solid hsl(218, 17%, 91%);
      height: 70px;
      padding: 0.5em 1em;
      border-radius: $date-border-radius;

      &.rbc-today {
        width: $today-day-width;
        background-color: hsl(273, 82%, 98%);
        border-bottom: 5px solid hsla(261, 52%, 37%, 1);
        border-radius: $date-border-radius;
        & > a {
          & > div {
            div {
              text-transform: capitalize;
              color: hsl(262, 49%, 57%);
            }
            div:nth-child(2) {
              font-weight: 600;
            }
          }
        }
      }

      & > button {
        & > div {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: flex-end;
          gap: 1em;
          div {
            text-transform: capitalize;
          }
        }
        // &, &:active, &:visited {
        //   color: inherit;
        //   text-decoration: none;
        // }
      }
    }
  }

  .rbc-day-slot .rbc-events-container {
    // margin-right: 0px;
    .rbc-event {
      padding: 4.5px;
      border-radius: $event-border-radius;
    }

    & .dashboard-event-VIRTUAL {
      // background-color: #f6ebfe;
      // border: 1px solid #dcc8f5;
      background-color: transparent;
      border: none;
      .rbc-event-content {
        border-radius: $event-border-radius;
        padding: 0.5em;
        background-color: #f6ebfe;
        border: 1px solid #dcc8f5;
        overflow: hidden;
      }
      &.dashboard-event-status-BOOKED,
      &.dashboard-event-status-NOSHOW {
        .rbc-event-content {
          border-radius: $event-border-radius;
          padding: 0.5em;
          background-color: #f6ebfe;
          border: 1px solid #dcc8f5;
          overflow: hidden;
        }
      }
      &.dashboard-event-status-PENDING {
        .rbc-event-content {
          border-radius: $event-border-radius;
          padding: 0.5em;
          background-color: #f6ebfe;
          border: 1px dashed hsla(232, 36%, 72%, 1);
          overflow: hidden;
        }
      }
      &.dashboard-event-status-CANCELLED,
      &.dashboard-event-status-CANCELLED {
        .rbc-event-content {
          border-radius: $event-border-radius;
          padding: 0.5em;
          background: #fcfdfd;
          border: 1px solid #fecdd3;
          overflow: hidden;
        }
      }

      // div {
      //   color: hsl(261, 52%, 37%);
      //   font-weight: 500;
      //   overflow-wrap: anywhere;
      //   overflow-y: auto;
      // }
    }
    & .dashboard-event-CLINIC {
      // background-color: rgba(222, 227, 248, 1);
      // border: 1px solid #bfc7f2;
      background-color: transparent;
      border: none;
      &.dashboard-event-status-PENDING {
        .rbc-event-content {
          border-radius: $event-border-radius;
          background-color: rgba(222, 227, 248, 1);
          padding: 0.5em;
          border: 1px dashed hsla(232, 36%, 72%, 1);
          overflow: hidden;
          div{
            div{
              overflow: hidden;
              white-space: nowrap;
              div{
                text-overflow: ellipsis;
              }
            }
          }
        }
      }

      &.dashboard-event-status-CANCELLED,
      &.dashboard-event-status-DECLINED {
        .rbc-event-content {
          border-radius: $event-border-radius;
          background: #fcfdfd;
          padding: 0.5em;
          border: 1px solid #fecdd3;
          overflow: hidden;

        }
      }
    }

    & .dashboard-event-CLINIC.dashboard-event-status-BOOKED,
    .dashboard-event-CLINIC.dashboard-event-status-NOSHOW {
      // background-color: hsla(228, 65%, 92%, 0.5);
      // border: 1px solid hsl(231, 66%, 85%);
      .rbc-event-content {
        border-radius: $event-border-radius;
        padding: 0.5em;
        background-color: rgba(222, 227, 248, 1);
        border: 1px solid hsl(231, 66%, 85%);
        overflow: hidden;
      }

      div {
        color: hsl(232, 36%, 38%);
      }
    }

    & .dashboard-event-.dashboard-event-status-BOOKED {
      .rbc-event-label {
        display: none;
      }
      div {
        overflow: hidden;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // color: hsla(218, 15%, 65%, 1);
        color: var(--secondary-500);
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .rbc-time-view-resources .rbc-day-slot {
    min-width: unset;
    width: calc(($header-cell-width - ($today-day-width)) / ($week-days - 1));
    .rbc-timeslot-group {
      .rbc-time-slot:nth-child(2) {
        border-top-style: dashed;
      }
    }
    &.next-week {
      width: calc(($header-cell-width) / ($week-days));
    }
    &.rbc-today {
      width: $today-day-width;
      background-color: transparent;
    }
  }

  & .dashboard-event- {
    background-color: transparent;
    border: none;
    overflow: hidden;
    // background-position: -19px -19px;
    .rbc-event-content {
      // background: hsla(218, 17%, 91%, 1);
      // background-image: radial-gradient(
      //   hsla(217, 16%, 84%, 1) 1px,
      //   transparent 0
      // );
      // background-size: 10px 10px;
      // border: 1px solid hsl(218, 17%, 91%);
      // border-radius: 8px;
      background-color: var(--secondary-100);
      color: var(--secondary-500);
      border: 1px solid var(--secondary-500);
      // border-left: 4px solid var(--secondary-500);
      border-radius: 8px;
      padding:0.5em;
    }
  }

  &.adjusted-week-view {
    .rbc-time-view.rbc-time-view-resources {
      overflow-x: scroll;
    }
    .rbc-time-header.rbc-overflowing {
      overflow: scroll;
    }
    .rbc-time-header {
      .rbc-time-header-content {
        min-width: unset;
        width: 100%;
        flex: none;
        .rbc-time-header-cell {
        }
      }
    }
    .rbc-time-view-resources .rbc-day-slot {
      min-width: calc(
        ($header-cell-width - ($today-day-width)) / ($week-days - 1)
      );
      width: calc(($header-cell-width - ($today-day-width)) / ($week-days - 1));
      &.rbc-today {
        width: $today-day-width;
        background-color: transparent;
        min-width: $today-day-width;
      }
    }
    &.next-week {
      width: calc(($header-cell-width) / ($week-days));
    }
    // &.rbc-today {
    //   width: $today-day-width;
    //   background-color: transparent;
    // }
  }
}

.calendar-month-view {
  background: hsl(210, 20%, 98%);
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background: transparent;
    border: none;
    height: 80px;
    &:focus {
      outline: none;
    }
  }
  .rbc-timeslot-group,
  .rbc-time-content,
  .rbc-time-content > * + * > *,
  .rbc-time-content > .rbc-time-gutter,
  .rbc-label.rbc-time-header-gutter {
    border: none;
  }
  overflow: auto;

  .rbc-header,
  .rbc-header + .rbc-header,
  .rbc-day-bg + .rbc-day-bg,
  .rbc-month-row + .rbc-month-row {
    border: none;
  }

  & .rbc-row.rbc-month-header {
    gap: 1em;
    margin-bottom: 1em;
    position: sticky;
    top: 5px;
    z-index: 99;

    .rbc-header {
      border: 1px solid hsl(218, 17%, 91%);
      background: hsl(210, 20%, 98%);
      border-radius: $date-border-radius;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        div {
          font-weight: 700;
        }
      }
    }
  }
  & .rbc-month-row {
    height: 130px;
    overflow: unset;
    margin-bottom: 1em;
    .rbc-row-bg {
      background: hsl(210, 20%, 98%);
      gap: 1em;
      & .rbc-day-bg {
        border: 1px solid hsl(218, 17%, 91%);
        background-color: white;
        padding: 1em;
        border-radius: $event-border-radius;
        &.isPastDate {
          cursor: not-allowed;
          background-color: #f2f4f7;
        }
        &.rbc-today {
          background-color: #825ac7;
        }
      }
    }
    .rbc-row-content {
      &.rbc-row-content-scrollable {
        height: 130px;
      }
      .rbc-row {
        gap: 1em;
      }
      & .rbc-date-cell {
        display: flex;
        flex-direction: row;
        padding-top: 1em;
        padding-inline: 0.7em;
        margin-bottom: 0.5em;
        width: 100%;
        &.rbc-current.rbc-now {
          & > button {
            color: white;
          }
        }
        & > button {
          font-weight: 700;
        }
      }
      .rbc-row-content-scroll-container .rbc-row {
        gap: 0em;
      }
    }
  }
}

.dashboard-calendar-day-view {
  .rbc-time-header-content {
    min-width: 240px;
  }
  .rbc-day-slot {
    min-width: 239px;
  }
}

.rbc-day-slot .isPastSlot {
  background-color: #f2f4f7;
  cursor: not-allowed;
}

.rbc-day-slot .isAvailableSlot {
  background-color: #ffffff;
  cursor: pointer !important;
  z-index: 1;
  flex-basis: 0;
}

.rbc-day-slot .isBookedSlot {
  background-color: #ffffff;
  cursor: pointer !important;
  z-index: 1;
  flex-basis: 0;
}

.isAvailableSlot {
  &:hover {
    text-align: center;
    border-width: 1px !important;
    border-style: dashed;
    border-color: #000000;
    .timeSlot {
      display: flex;
      text-align: center;
    }
  }
}

.isPastDate {
  cursor: not-allowed;
  background-color: #f2f4f7;
}

.rbc-time-slot {
  max-height: 50%;
}

.timeSlot {
  display: none;
  padding-left: 5px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.timeslot-center{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

// .calendar-practice-availability-view {
//   $header-cell-width: 100%;
//   $today-day-width: 15%;
//   $offset: 4%;
//   $week-days: 7;

//   .rbc-timeslot-group,
//   .rbc-time-content,
//   .rbc-time-content > * + * > *,
//   .rbc-time-content > .rbc-time-gutter,
//   .rbc-label.rbc-time-header-gutter {
//     border: none;
//   }

//   .rbc-time-header .rbc-time-header-content {
//     border: none;
//   }

//   .rbc-time-content {
//     padding-top: 1em;
//     .rbc-time-column {
//       & .rbc-timeslot-group {
//         min-height: 140px;
//       }
//     }
//   }

//   .rbc-time-header-cell {
//     width: $header-cell-width;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     .rbc-header {
//       min-width: unset;
//       width: calc(
//         ($header-cell-width - ($today-day-width + $offset)) / ($week-days - 1)
//       );
//       flex: none;
//       border: none;
//       border: 1px solid hsl(218, 17%, 91%);
//       height: 70px;
//       padding: 0.5em 1em;
//       border-radius: $date-border-radius;
//       font-size: 30px;
//       font-weight: 400;

//       &.rbc-today {
//         width: $today-day-width;
//         background-color: hsl(273, 82%, 98%);
//         border-bottom: 5px solid hsla(261, 52%, 37%, 1);
//         border-radius: $date-border-radius;
//         & > a {
//           & > div {
//             div {
//               text-transform: capitalize;
//               color: hsl(262, 49%, 57%);
//             }
//             div:nth-child(2) {
//               font-weight: 600;
//             }
//           }
//         }
//       }

//       & > button {
//         & > div {
//           display: flex;
//           flex-direction: row-reverse;
//           align-items: center;
//           justify-content: flex-end;
//           gap: 1em;
//           div {
//             text-transform: capitalize;
//           }
//         }
//       }
//     }
//   }

//   .rbc-day-slot .rbc-events-container {
//     margin-right: 6px;
//     .rbc-event {
//       border-radius: $availability-event-border-radius;
//       background-color: transparent;
//       box-shadow: 0px 0px 3px rgba(117, 117, 117, 0.25);
//       border-bottom: none;
//       border-left: none;
//       border-right: none;
//     }
//     // & .dashboard-event-0-user {
//     //   background-color: #FFF4ED;
//     // }
//     // & .dashboard-event-1-user {
//     //   background-color: #F6F0FF;
//     // }
//     // & .dashboard-event-2-user {
//     //   background-color: #FFF3F9;
//     // }
//     & .dashboard-event-3-user {
//       background-color: #E4FFFE;
//     }
//     & .dashboard-event-4-user {
//       background-color: #E4FFFE;
//     }
//     & .dashboard-event-5-user {
//       background-color: #E4FFFE;
//     }
//     & .dashboard-event-6-user {
//       background-color: #FFF4ED;
//     }
//     & .dashboard-event-7-user {
//       background-color: #FFF3CA;
//     }
//     & .dashboard-event-8-user {
//       background-color: #FFF3F9;
//     }
//     & .dashboard-event-9-user {
//       background-color: #C9EBFF;
//     }
//     & .dashboard-event-10-user {
//       background-color: #FFF3F9;
//     }
//   }
//   & .dashboard-event-0-user {
//     border-top: 5px solid #D27634;
//     border-radius: $event-border-radius;
//     background: linear-gradient(180deg, #FFF4ED 0%, #FFFFFF 100%);
//   }

//   & .dashboard-event-1-user {
//     border-top: 5px solid #7B49D2;
//     border-radius: $event-border-radius;
//     background: linear-gradient(180deg, #F6F0FF 0%, #FFFFFF 100%);
//   }

//   & .dashboard-event-2-user {
//     border-top: 5px solid #D14789;
//     border-radius: $event-border-radius;
//     background: linear-gradient(180deg, #FFF3F9 0%, #FFFFFF 100%);
//   }

//   & .dashboard-event-3-user {
//     border-top: 5px solid #40ADA7;
//     border-radius: $event-border-radius;
//   }

//   & .dashboard-event-4-user {
//     border-top: 5px solid #85D1CD;
//     border-radius: $event-border-radius;
//   }

//   & .dashboard-event-5-user {
//     border-top: 5px solid #00A110;
//     border-radius: $event-border-radius;
//   }

//   & .dashboard-event-6-user {
//     border-top: 5px solid #A7527B;
//     border-radius: $event-border-radius;
//   }

//   & .dashboard-event-7-user {
//     border-top: 5px solid #D27634;
//     border-radius: $event-border-radius;
//   }

//   & .dashboard-event-8-user {
//     border-top: 5px solid #FFDC4B;
//     border-radius: $event-border-radius;
//   }

//   & .dashboard-event-9-user {
//     border-top: 5px solid #3385FF;
//     border-radius: $event-border-radius;
//   }

//   & .dashboard-event-10-user {
//     border-top: 5px solid #6D6BE1;
//     border-radius: $event-border-radius;
//   }
//   .rbc-time-view {
//     border: none;
//   }
//   .rbc-time-view-resources .rbc-day-slot {
//     min-width: unset;
//     width: calc(($header-cell-width - ($today-day-width)) / ($week-days - 1));
//     &.next-week {
//       width: calc(($header-cell-width) / ($week-days));
//     }
//     &.rbc-today {
//       width: $today-day-width;
//       background-color: transparent;
//     }
//   }
// }

.rbc-date-cell {
  text-align: left;


  & > button {
    font-weight: 700;
  }

  &.rbc-current {
    & > button {
      color: #6941C6;
    }
  }
}

.calendar-practice-availability-view {

  .rbc-day-slot .rbc-events-container:not(:empty) {
    margin-right: 4px;
    margin-left: 4px;
    .rbc-event {
      border-radius: $availability-event-border-radius;
      background-color: transparent;
      box-shadow: 0px 0px 3px rgba(117, 117, 117, 0.25);
      border-bottom: none;
      border-left: none;
      border-right: none;
    }
  }

  & .dashboard-event-0-user {
    border-top: 5px solid #D27634;
    border-radius: $event-border-radius;
    background: linear-gradient(180deg, #FFF4ED 0%, #FFFFFF 100%);
  }

  & .dashboard-event-1-user {
    border-top: 5px solid #7B49D2;
    border-radius: $event-border-radius;
    background: linear-gradient(180deg, #F6F0FF 0%, #FFFFFF 100%);
  }

  & .dashboard-event-2-user {
    border-top: 5px solid #D14789;
    border-radius: $event-border-radius;
    background: linear-gradient(180deg, #FFF3F9 0%, #FFFFFF 100%);
  }

  & .dashboard-event-3-user {
    border-top: 5px solid #40ADA7;
    border-radius: $event-border-radius;
    background: linear-gradient(180deg, #E4FFFE 0%, #FFFFFF 100%);
  }

  & .dashboard-event-4-user {
    border-top: 5px solid #85D1CD;
    border-radius: $event-border-radius;
    background: linear-gradient(180deg, #E4FFFE 0%, #FFFFFF 100%);
  }

  & .dashboard-event-5-user {
    border-top: 5px solid #00A110;
    border-radius: $event-border-radius;
    background: linear-gradient(180deg, #E4FFFE 0%, #E1FFE4 0.01%, #FFFFFF 100%);
  }

  & .dashboard-event-6-user {
    border-top: 5px solid #A7527B;
    border-radius: $event-border-radius;
    background: linear-gradient(180deg, #FFF4ED 0%, #FFFFFF 100%);
  }

  & .dashboard-event-7-user {
    border-top: 5px solid #D27634;
    border-radius: $event-border-radius;
    background: linear-gradient(180deg, #FFF3CA 0%, #FFFFFF 100%);
  }

  & .dashboard-event-8-user {
    border-top: 5px solid #FFDC4B;
    border-radius: $event-border-radius;
    background: linear-gradient(180deg, #FFF3F9 0%, #FFFFFF 100%);
  }

  & .dashboard-event-9-user {
    border-top: 5px solid #3385FF;
    border-radius: $event-border-radius;
    background: linear-gradient(180deg, #C9EBFF 0%, #FFFFFF 100%);
  }

  & .dashboard-event-10-user {
    border-top: 5px solid #6D6BE1;
    border-radius: $event-border-radius;
    background: linear-gradient(180deg, #FFF3F9 0%, #FFFFFF 100%);
  }

  & .dashboard-event-11-user {
    border-top: 5px solid #636363;
    border-radius: $event-border-radius;
    background: linear-gradient(180deg, #FFF4ED 0%, #FFFFFF 100%);
  }
}


// new styles
.dashboard-calendar.dashboard-calendar-week-view {
  & .rbc-event.dashboard-event-styles-blocked-interval{
    background-color: #F2F4F7;
    opacity: 0.9;
    background-size: 10px 10px;
  }
}
.dashboard-calendar.dashboard-calendar-day-view {
  & .rbc-event.dashboard-event-styles-blocked-interval{
    background-color: #F2F4F7;
    opacity: 0.9;
    background-size: 10px 10px;
    border: 1px solid #D0D5DD
  }
}

.dashboard-calendar.dashboard-calendar-month-view {
  & .dashboard-month-event-blocked-interval{
    background-color: #F2F4F7;
    opacity: 0.9;
    background-size: 8px 8px;
    // border: 1px solid #D0D5DD
  }
}

//pending appointment styles
.dashboard-calendar.dashboard-calendar-week-view {
  & .rbc-event.dashboard-event-styles-pending-appointment{
    opacity: 1;
  }
}
.dashboard-calendar.dashboard-calendar-day-view {
  & .rbc-event.dashboard-event-styles-pending-appointment{
    opacity: 1;
    border: 1px solid #D0D5DD
  }
}

.dashboard-calendar.dashboard-calendar-month-view {
  & .dashboard-month-event-pending-appointment{
    opacity: 1;
  }
}
