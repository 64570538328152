
.care-plan-medications-collapse .ant-collapse-content {
    border-width: 0;
  }
 
.care-plan-medications-collapse .ant-collapse-header {
  padding-top: 12px !important;
  padding-bottom: 0px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}