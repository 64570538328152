.wrapper {
  margin-top: 10px;
}
.wrapper .ant-pagination-item {
  border-width: 0 !important;
}

.wrapper .ant-pagination-item.ant-pagination-item-active {
  background-color: #825ac7 !important;
  border-radius: 4px !important;
  color: #fff !important;
}

.wrapper .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 0px !important;
}
