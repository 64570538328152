.fh-tooltip-container {
  position: relative;
  display: inline-block;
}

.fh-tooltip-box {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.fh-tooltip-container:hover .fh-tooltip-box {
  opacity: 1;
  visibility: visible;
}

.fh-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

/* Tooltip positions */
.fh-tooltip-top {
  bottom: 130%;
  left: 50%;
  transform: translateX(-50%);
}

.fh-tooltip-top .fh-tooltip-arrow {
  top: 100%;
  left: 50%;
  border-width: 5px 5px 0 5px;
  border-color: #333 transparent transparent transparent;
  transform: translateX(-50%);
}

.fh-tooltip-right {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.fh-tooltip-right .fh-tooltip-arrow {
  top: 50%;
  left: -5px;
  border-width: 5px 5px 5px 0;
  border-color: transparent #333 transparent transparent;
  transform: translateY(-50%);
}

.fh-tooltip-bottom {
  top: 130%;
  left: 50%;
  transform: translateX(-50%);
}

.fh-tooltip-bottom .fh-tooltip-arrow {
  bottom: 100%;
  left: 50%;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #333 transparent;
  transform: translateX(-50%);
}

.fh-tooltip-left {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
}

.fh-tooltip-left .fh-tooltip-arrow {
  top: 50%;
  right: -5px;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #333;
  transform: translateY(-50%);
}
