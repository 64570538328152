#tableTopSearchBar {
  min-height: 40px;
}
#tableTopSearchBar > div {
  height: 40px;
  overflow: hidden;
 }

 #tableTopSearchBarStyle > div {
  height: 32px;
  overflow: hidden;
 }
