.alerts-view .ant-collapse-header {
    border-radius: 0px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

.ant-collapse.ant-collapse-icon-position-start.alerts-view {
  border-radius: 8px !important;
  overflow: hidden;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

.alerts-action-popover .ant-popover-inner-content {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.alertsTable .ant-table-cell {
  padding: 12px;
}
.alertsTable .ant-table-column-sorters {
  padding-left: 4px;
}

.alertsTable .ant-table-row {
  cursor: default !important;
}
.alertsTable.care-gap.ant-table-wrapper {
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  overflow: hidden;
}

.create-note-from-alert-drawer {
  .ant-drawer-body {
      padding: 0;
  }

  .ant-drawer-header {
      padding: 0;

  }
}

.confirmation-dialog .ant-modal {
  border-radius: 12px;
}

.confirmation-dialog .ant-modal-content {
  border-radius: 12px;
}
.alertsTable.care-gap.ant-table-wrapper {
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  overflow: hidden;
}
.referral-order-card .ant-popover-inner-content {
  padding: 8px !important;
}

.referral-order-document-selection .ant-card-body {
  padding: 2px !important;
}

.card-container .ant-card.ant-card-bordered .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
 padding: 4px 2px !important;
}

.card-container .ant-card.ant-card-bordered .ant-card-head {
  border-bottom: none !important;
  margin-bottom: 0 !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.card-container .ant-card.ant-card-bordered .ant-card-body {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
