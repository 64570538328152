.select-template-category .ant-select-selection-item {
  margin-top: 10px;
}

.select-template-category .ant-select-selector {
  border-radius: 8px;
  box-shadow: none;
  border-color: #e5e5e5 !important;
  background-color: #f9fafb;
  height: 53px !important;
}

.select-template-category .ant-select-selection-search-input {
  height: 53px !important;
}

.select-template-category .ant-select-selection-item {
  margin-top: 0px;
}

/* .editor-container {
  height: 400px;
  overflow-y: auto;
} */

/* .ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
} */

.jodit-container:not(.jodit_inline) {
  border-left: none;
  border-right: none;
  border-top: none;
  background: none;
  border-radius: 0;
  padding-bottom: 5px;
}

.jodit-toolbar__box {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
}

.jodit-icon_brush.jodit-icon {
  fill: black !important;
}
