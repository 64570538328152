.ant-picker,
.ant-picker-focused {
  border-radius: 8px;
  box-shadow: none;
  border-color: #e5e5e5;
  background-color: #f9fafb;
  align-items: center;
}

.ant-select {
  border-radius: 8px;
  box-shadow: none;
  border-color: #e5e5e5;
  background: transparent;
  align-items: center;
}

.ant-select-selector {
  border-radius: 8px;
  box-shadow: none;
  border-color: #e5e5e5;
  background-color: #f9fafb;
  height: auto;
  min-height: 44px;
  align-items: center;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px;
  box-shadow: none;
  border-color: #e5e5e5;
  background-color: #fff;
  height: auto;
  min-height: 44px;
  align-items: center;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 8px !important;
  box-shadow: none !important;
  border-color: #e5e5e5 !important;
  background-color: #f9fafb !important;
  align-items: center;
  border-width: 1 !important;
  border-right-width: 1 !important;
}
.ant-picker-input input::placeholder {
  color: #D0D5DD;
}

.ant-picker-input > input {
  font-size: 12;
}
/* .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  position: relative;
    display: block;
    min-height: 32px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    transition: background 0.3s ease;
} */

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #e3e3e3;
}

.ant-layout-content
  .addEDitUserMultiSelect
  .participant-auto-complete
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
  min-height: 44;
}

.participant-auto-complete .ant-select-selector {
  /* height: auto !important; */
}

.appointment-notes {
  min-height: 150px;
  border-left: 1px solid var(--light-200);
  border-right: 1px solid var(--light-200);
  border-bottom: 1px solid var(--light-200);
  border-radius: 4px;
}

.participant-auto-complete .ant-select-selector {
  /* height: auto !important; */
}

.ant-select-multiple .ant-select-selection-item {
  background-color: var(--light-200);
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  align-items: flex-start;
  justify-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  display: flex;
}

.ant-select-single:hover {
  background-color:  var(--light-100) !important;
}

.appointment-timezone.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none !important;
  border-color: transparent !important;
  background-color: transparent !important;
}

.appointment-timezone .ant-select-selection-item{
 font-weight:600 !important;
}

.sms-inbox-drawer .ant-select-selection-item div{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-select-selector {
  border-radius: 8px;
  box-shadow: none;
  border-color: #e5e5e5;
  height: auto;
  min-height: 37px;
  align-items: center;
}

.date-picker-background-color.ant-picker {

}
