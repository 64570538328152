.ant-popover > .ant-popover-content {
    border-radius: 24;
}

.globalActions .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  padding: 24px 26px;
}
.ant-popover {
    z-index: 1000 !important;
}
