.task-table-view td.ant-table-cell.ant-table-cell-fix-left,
.task-table-view td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-row-hover {
  background-color: #fff !important;
}

.task-table-view td.ant-table-cell.ant-table-cell-fix-left-last,
.task-table-view td.ant-table-cell.ant-table-cell-fix-left-last.ant-table-cell-row-hover {
  background-color: #fff !important;
}

.task-table-view td.ant-table-cell.ant-table-cell-fix-right,
.task-table-view td.ant-table-cell.ant-table-cell-fix-right-first.ant-table-cell-row-hover {
  background-color: #fff !important;
}

.task-table-view td.ant-table-column-sort {
  background: #ffffff;
}

.task-table-view-gray {
  .ant-table-footer {
    background-color: transparent !important;
    overflow: hidden !important;
    border-top: 1px solid #f0f0f0;
  }  
  .ant-table {
    border-radius: 8px;
    overflow: hidden;
  }  
} 