/* Chatbot container */
.chatbot-container {
  margin: 0 auto;
}

/* Chat messages */
.chat-messages {
  overflow-y: auto;
}

.unit-ai-date {
  font-weight: 400;
  line-height: 1.5em;
  font-size: 12px;
  color: rgb(102, 112, 133);

}
/* Individual chat messages */
.user {
  
  align-self: flex-end;
  display: flex;
  width: fit-content;
  justify-content: center;
}

.user-text {
  margin-right: 1px;
  background: #fff;
  white-space: 'pre-line';
  color: #fff;
  background-color: rgb(130, 90, 199);
  min-width: 200px;
  box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px, rgb(0, 0, 0) 0px 0px 0px;
  margin-right: 1px;
  margin-bottom: 3px;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 40px;
  border-radius: 8px;
  border-style: solid;
  border-width: 0px;
}

.chatbot {
  background: #fff;
  color: #333;
  white-space: 'pre-line';
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 3px;
  display: flex;
  min-width: 200px;
  justify-content: flex-start;
  width: max-content;
  max-width: 80%;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 40px;
  color: rgb(16, 24, 40);
  border-style: solid;
  border-radius: 8px;
  border-width: 1px;
  border-color: rgb(228, 231, 236);
  box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px, rgb(0, 0, 0) 0px 0px 0px;
}

/* User input field */
.chatbot-container input[type="text"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}


.spinner-input-container {
  position: relative;
}

.chatbot-container input {
  padding-right: 30px; /* Provide space on the right for the spinner */
}

.spinner {
  position: absolute;
  top: 30%;
  right: 10px; /* Adjust the right position as needed */
  transform: translateY(-50%);
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid rgb(130, 90, 199); /* Change the color to your preference */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.open-ai-text-area:focus {
  border-color: rgb(130, 90, 199); /* Change this to the color you prefer */
}
