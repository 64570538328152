.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #f9fafb;
}

.table-row-dark:hover {
  background-color: #f9fafb !important;
}

.table-row-light:hover {
  background-color: #ffffff !important;
}

.ant-table-thead > tr > th {
  color: #000000d9;
  background: #ffffff !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}
.workflow-table  .ant-table-body {
  max-height: 400px !important;
}

.ant-tabs-nav {
  background-color: #ffffff !important;
  padding-left: 8px !important;
  margin-bottom: 2px !important;
}

.ant-table-row {
  cursor: pointer;
}

.import-cursor .ant-table-row {
  cursor: auto;
}


.workflow-list-table-view {
  height: auto;
}

.workflow-loader {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}


.active-trigger {
  border: solid;
  border-color:  var(--primary-500);
  background-color:  var(--primary-500);
  color: white;
  border-width: thin;
  padding: 5px 49px;
  width: 100%;
  border-radius: 3px;
}

.deActive-trigger {
  border: solid;
  border-color:#ddd5d5;
  padding: 5px 49px;
  width: 100%;
  border-width: thin;
  border-radius: 3px;
}


.custom-new-workflow-trigger .ant-select-selector,
.custom-new-workflow-trigger .ant-select-focused,
.custom-new-workflow-trigger .ant-input-number{
  border-radius: 4px !important;
  border-width: 0.5px !important;
  border-color: '#A7AFBE' !important;
  background-color: white !important;
}
.custom-new-workflow-trigger .trigger-select:hover,
.custom-new-workflow-trigger .ant-select-selector:hover,
.custom-new-workflow-trigger .ant-select-focused:hover,
.custom-input-box-shadow:hover,
.custom-new-workflow-trigger .ant-picker:hover,
.custom-new-workflow-trigger textarea:hover,
.custom-new-workflow-trigger .quill:hover,
.custom-new-workflow-trigger .ant-input-number:hover{
  /* box-shadow: rgb(173, 125, 255) 0px 0px 5px !important; */
  box-shadow: 0 0 0 0.2rem rgba(173, 125, 255, 0.25) !important;
  width: '100%';
  border-radius: 4px;
  transition: 0.1s ease;
}

.custom-new-workflow-trigger textarea:focus{
  border-color: rgb(173, 125, 255) !important;
}

.custom-new-workflow-trigger .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
  background-color: #FFFFFF !important;
  align-items: center;
  border-width: .5 !important;
}
.custom-new-workflow-trigger .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector :focus{
  border-radius: 4px !important;
  background-color: #FFFFFF !important;
  align-items: center;
  border-width: 0.5 !important;
  transition: 0.1s ease;
}


/* .custom-new-workflow-trigger :not(.ant-select-selector) input:hover {
  box-shadow: rgb(173, 125, 255) 0px 0px 5px !important;
  width: 100%;
  border-radius: 4px;
} */


/* .workflow-antd-input:hover{
  box-shadow: rgb(173, 125, 255) 0px 0px 5px !important;
  width: '100%';
  border-width: 1px;
  border-radius: 4px;
  border-color: rgb(173, 125, 225) !important;
} */




.workflow-antd-input .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: rgb(173, 125, 225) !important;
  /* box-shadow: rgb(173, 125, 255) 0px 0px 5px !important; */
  box-shadow: 0 0 0 0.2rem rgba(173, 125, 255, 0.25) !important;
  border-right-width: 1px;
  z-index: 1;
  transition: 0.1s ease;
}


.workflow-antd-input .ant-input-affix-wrapper:focus,.workflow-antd-input .ant-input-affix-wrapper-focused {
  border-color: rgb(173, 125, 225) !important;
  /* box-shadow: rgb(173, 125, 255) 0px 0px 5px !important; */
  box-shadow: 0 0 0 0.2rem rgba(173, 125, 255, 0.25) !important;
  border-right-width: 1px;
  outline: 0;
  transition: 0.1s ease;
}

/* .workflow-action-nodes:hover{
  background-color: rgba(140, 90, 226, 0.1);
  transition: 0.1s ease;
  border-radius: '8px';
} */

/* .workflow-operation-nodes{
  width: 100%;
  padding-top:  16;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  padding-left: 20;
  padding-right: 20;
} */

/* .workflow-operation-nodes:hover{
  background-color: rgba(111, 122, 144, 0.1);
  transition: 0.1s ease;
  border-radius: '8px';
} */

/* .workflow-condition-nodes:hover{
  background-color: rgba(232, 30, 99, 0.1);
  transition: 0.1s ease;
  border-radius: '8px';
} */


.workflow-tab-nav .ant-tabs-nav {
  background-color: #ffffff !important;
  padding-left: 0 !important;
}


.workflow-ant-input-number .ant-input-number ::placeholder{
  color: #A6AFBE;
}

.custom-new-workflow-trigger .ql-editor:focus {
  border: 0.5px solid rgb(173, 125, 255) !important;
  /* box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important; */
  transition: 0.1s ease;
}

.custom-gradient{
  border  : 0.5px solid var(#C57CF8, #1E9DAE) !important;
  border: none;
  background: var(--Colors-Accent-Light-Gradient, linear-gradient(238deg, rgba(30, 157, 174, 0.20) 0.27%, rgba(212, 120, 255, 0.20) 102.44%)) !important;

}

.wokflowOnSave .ant-modal-header {
  padding: 0px 0px 2px 0px !important;
  border-radius: 4px !important;
  text-align: center;
}

.wokflowOnSave .ant-modal-body {
  padding: 0px !important;
}

.wokflowOnSave .ant-modal-footer {
  padding: 16px 0px 0px 0px !important;
  border-radius: 0px !important;
}

.wokflowOnSave .ant-modal-content {
  padding: 20px !important;
  gap: 16px !important;
  border-radius: 12px !important;
}
.workflowModalV2 .ant-modal-body{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 20 !important;
  padding-right: 20 !important;
}
.workflowModalV2 .ant-modal-footer{
  padding-bottom: 20px !important;
  padding-top: 16px !important;
}
.workflowModalV2 .ant-modal-header{
  padding-top: 20px !important;
  padding-bottom: 8px !important;
  border-top-right-radius : 18px !important;
  border-top-left-radius : 18px !important;
}
.workflowModalV2 .ant-btn{
  border-radius: 4px !important;
}

.workflow-disabled-row {
  cursor: not-allowed;
}

.workflow-disabled-row:hover {
  cursor: not-allowed;
}

.workflow-location-select-error .ant-select-selector {
  cursor: text;
  background-color: #DC2625;
  border-width: 1px !important;
}
