  .custom-dropdown .ant-select-selector {
    /* border-color: #8a94a8 !important; */
    background-color: #fff !important;
    border-left-width: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .custom-dropdown.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector, .custom-dropdown:hover {
    background-color: #fff !important;
  }

  .custom-dropdown.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 32px !important;
  }

  .custom-dropdown-without-effects.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector, .custom-dropdown-without-effects:hover {
    background-color: #fff !important;
    border-width: 0px !important;
  }

  .custom-dropdown-without-effects.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-width: 0px !important;
    background-color: #fff !important;
  }