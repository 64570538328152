.llm-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  /* background: linear-gradient(237.73deg, #d2ebef 0.27%, #f5e4fe 102.44%); */
  border-bottom-width: 0;
}

.llm-collapse .ant-collapse-content.ant-collapse-content-active {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

/* .ant-collapse-content.ant-collapse-content-active {
  border-bottom-left-radius: 9px !important;
  border-bottom-right-radius: 9px !important;
} */

.llm-content {
  /* background: linear-gradient(to right, #f4e3fd, #d5eaf0 102.44%); */
  /* border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px; */
  border-width: 0;
  border-top-width: 0;
  /* border-style: solid; */
  padding: 0;
  /* border-image: linear-gradient(237.73deg, #1e9dae 0.27%, #d478ff 102.44%) 1; */
  border-image-slice: 1;
  border-top-width: 0.5px;
}

.llm-panel {
  /* background: #dae9f3 !important; */
}


.llm-panel .ant-select {
  border-radius: 1px !important;
}

.custom-overflow {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(255,255,255,1) 15%, rgba(76,121,9,0) 100%);
  height: 40px;
  bottom: 40px;
}

.r-borderColor-1yj3uqq {
  border-color:#D0D5DD ;
}