.modal-container {
    position: fixed;
    width: 540px;
    height: 56px;
    left: 37%;
    top: 89%;
    z-index: 9999;
    box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 8px;
    padding: 12px;
  }
  