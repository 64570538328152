.create-instant-chat{
  .ant-drawer-body {
      padding: 0;
  }

  .ant-drawer-header {
      padding: 0;

  }
}
