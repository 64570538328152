.msg-attachment {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 0px;
}

.msg-attachment .ant-upload-select-text {
  margin-top: 0px;
}

.messagewindow .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-layout-content.msg-reply-drawer-editor .toastui-editor-defaultUI-toolbar {
  border: 0px;
}
.ant-layout-content.msg-reply-drawer-editor .toastui-editor-ww-container,
.ant-layout-content.msg-reply-drawer-editor .toastui-editor-defaultUI-toolbar {
  background-color: #fcfdfd !important;
  font-size: 15px;
  /* background-color: #fff !important; */
}
.ant-layout-content.msg-reply-drawer-editor
  .ProseMirror.toastui-editor-contents {
  border-radius: 10px;
  padding: 0 !important;
  padding: 10px 16px !important ;
  height: 94% !important;
  border: 1px solid #d0d5dd !important;
  margin-left: 16px !important;
  /* //margin-right: 16px !important; */
  background-color: #fcfcfd !important;
  font-size: 15px;
}
.ant-layout-content.msg-reply-drawer-editor .toastui-editor-defaultUI-toolbar {
  border-bottom: none;
}

.ant-layout-content.msg-reply-drawer-editor .toastui-editor-defaultUI {
  border-top: 0 !important;
}

.ant-layout-content.msg-reply-drawer-editors
  .ProseMirror.toastui-editor-contents {
  border-radius: 6px;
  padding: 0 !important;
  padding: 10px 16px !important ;
  border: 1px solid #d0d5dd !important;
  background-color: #fcfcfd !important;
  font-size: 15px;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
  background-color: #ffffff;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}

.ant-layout-content.messagewindow-editor-task-comment .ProseMirror.toastui-editor-contents {
  border-radius: 6px;
  height: 85px !important;
  border: 1px solid #d0d5dd !important;
  background-color: #fcfcfd !important;
  font-size: 15px;
}

.ant-layout-content.messagewindow-editor-task-comment-singleline .ProseMirror.toastui-editor-contents {
  border-radius: 6px;
  height: 50px !important;
  border: 1px solid #d0d5dd !important;
  background-color: #fcfcfd !important;
  font-size: 15px;
}

.ant-layout-content.messagewindow-editor-task-comment-error .ProseMirror.toastui-editor-contents {
  border-radius: 6px;
  height: 85px !important;
  border: 1px solid red !important;
  background-color: #fcfcfd !important;
  font-size: 15px;
}

.messagewindow .ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-layout-content.messagewindow-editor-task-comment .toastui-editor-defaultUI-toolbar {
  border: 0px;
  margin-left: 0%;
}

.ant-layout-content.messagewindow-editor-task-comment .toastui-editor-ww-container,
.ant-layout-contentmessagewindow-editor-task-comment .toastui-editor-defaultUI-toolbar {
  background-color: #fcfdfd !important;
  font-size: 15px;
  margin-left: 0%;
}

.ant-layout-content.messagewindow-editor-task-comment .toastui-editor-defaultUI-toolbar {
  border-bottom: none;
  margin-left: 0%;
  margin-left: -25px;
}

.ant-layout-content.messagewindow-editor-task-comment .toastui-editor-defaultUI {
  border-top: 0 !important;
}
.msg-reply-drawer-editor.internalMessage-editor .ProseMirror.toastui-editor-contents {
  border: 1px solid #f37232 !important;
  background-color: #FFFDFA !important;
}
