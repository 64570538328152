.marvel-device .screen {
  overflow-y: scroll;
}

// Overriding all bootstrap classes for mobile preview
// Setting width to 100%
.fh-phone-preview {
  %grid-column {
    flex: 0 0 100%;
    max-width: 100%;
  }

  $columns: 12;
  $infixes: sm, md, lg, xl, xxl;

  @each $infix in $infixes {
    @for $i from 1 through $columns {
      .col-#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }
  }
}
