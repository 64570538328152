
.calender-table-drawer .ant-table-cell{
    padding: 0px;
    border-width: 1px;
    border-color: #E4E7EC;
    border-style: solid;
}

.journey-table-drawer .ant-table-cell{
    padding: 0px;
}

.table-scroll {
    overflow-x: scroll;
}

.table-scroll::-webkit-scrollbar {
    width: 0px;
    /* width of the entire vertical scrollbar */
}

.table-scroll::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    /* color of the tracking area */
}

.table-scroll::-webkit-scrollbar-thumb {
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid rgb(95, 93, 89);
    /* creates padding around scroll thumb */
}

.new-ui-table-scroll #date-list-header,
.new-ui-table-scroll .date-list-header {
    position: sticky;
    top: 0;
    z-index: 99;
}

.new-ui-table-scroll #first-list-column,
.new-ui-table-scroll #columns-after-first {
    overflow-x: unset;
    overflow-y: unset;
}