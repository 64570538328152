/* main styles start */

.arco-layout-has-sider {
  /* width: 100% !important; */
  /* min-width: 84vw !important; */
  /* overflow-x: scroll; */
  width: 100% !important;
  min-width: 84vw !important;
  overflow-x: scroll !important;
}

/* /* main email editor */
#easy-email-editor {
  min-width: 650px !important;
  width: 100% !important;
}

/* .arco-layout-sider  */
/* .arco-layout-sider-light {
  width: 280px !important;
  min-width: 250px !important;
  max-width: 280px !important;
} */
/* layout bar */
/* .arco-layout-sider {
  width: 15vw !important;
  overflow-x: scroll !important;
} */

/* hiding layout bar */
.arco-layout-sider-light {
  width: 4vw !important;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
}

.arco-card-body {
  overflow-x: scroll !important;
  height: 100% !important;
  flex-direction: column !important;
}

/* drag and drop tool bar */
.arco-card-grid {
  width: 100% !important;
}

._customScrollBar_njli7_17 {
  /* width: 13vw !important;
  min-width: 13vw !important;
  max-width: 13vw !important; */
}

/* Text tools */
#Tools {
  background-color: yellow;
}

#Tools:nth-child(1):nth-child(1) {
  margin-right: 0px !important;
}
/* #Tools div:nth-child(1) {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  margin: 10px !important;
}


#Tools:first-child:first-child {
  margin-right: 0px !important;
} */

/* main styles end */

section.arco-layout {
  height: 80vh;
  flex: 1 1 0%;
  min-width: 650px;
  overflow: scroll !important;
}

aside.arco-layout-sider-light.arco-layout-sider {
  min-width: 300px !important;
}

._blockItem_1ajtj_6:nth-child(3n + 1) {
  margin-left: 0px !important;
}

._blockItem_1ajtj_6 {
  margin-right: 0px !important;
}

.arco-row-justify-start {
  justify-content: space-evenly !important;
}
