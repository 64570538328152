.fhir-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

.fhir-collapse .ant-collapse-header {
  background-color: #fff;
  height: 75px;
  align-items: center !important;
  border-bottom: 1px solid #e4e7ec;
  border-radius: 8px 8px 0px 0px !important ;
}

.fhir-collapse .ant-collapse-extra {
  background-color: #fff;
  margin-right: 12px;
}

.fhir-collapse .ant-collapse {
  border-radius: 8px !important;
  border: 1px solid #e4e7ec;
  /* overflow: ; */
}
.fhir-collapse .ant-collapse-header .ant-collapse-arrow {
  margin-right: 12px !important;
  margin-top: 2px !important;
}
.fhir-collapse .ant-collapse > .ant-collapse-item {
  border-bottom: none;
}

.fhir-collapse::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
}

.fhir-collapse::-webkit-scrollbar-button,
::-webkit-scrollbar-corner,
::-webkit-scrollbar-resizer {
  display: none;
}

.fhir-collapse::-webkit-scrollbar-button,
::-webkit-scrollbar-corner,
::-webkit-scrollbar-resizer {
  display: none;
}

.fhir-collapse::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border: 0px solid transparent;
  transition: background-color 200ms linear 0s;
}
.fhir-collapse::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.07);
  border: 0px solid transparent;
}
.fhir-collapse::-webkit-scrollbar-track-piece {
  background-clip: padding-box;
}

/* .ant-timeline-item-tail {
  position: absolute;
  top: 38px;
  left: 77px;
  height: calc(100%);
  border-left: 2px solid #f0f0f0;
  border-style: 2px solid #f0f0f0 dotted;
} */

.r-marginTop-156q2ks {
  margin-top: 10px;
}
.r-paddingTop-95jzfe {
  padding-top: -10px;
}

.ant-timeline-item-content {
  margin: 0px !important;
}
