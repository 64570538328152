.flex-start {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  flex: 1;
  justify-content: center;
}

.flex-div {
  display: flex;
  flex: 1;
}

.logo-div-padding-view {
  padding-left: 12px;
  margin-bottom: 18px;
  margin-top: 20px;
}

.logo-div-view {
  margin-bottom: 18px;
  margin-top: 20px;
}

.ml-1 {
  margin-left: 1px;
}


.account-name-text-style {
  font-size: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 400;
}
