#toast-ui-scheduled-list-edit .toastui-editor-toolbar {
display: none;
}
#toast-ui-scheduled-list-edit .toastui-editor-defaultUI {
  border-top: none !important;
}
#toast-ui-scheduled-list-edit .ProseMirror.toastui-editor-contents {
  border: 1px solid;
  border-color: #D0D5DD;
  border-radius: 6px;
  max-height: 90px;
  font-size: 16px;
  color: #667085;
}
/* .toast-ui-scheduled-list-edit > div {
  max-height: 140px;
} */
