.fold-progress-container {
  width: 100%;
  background-color: #f3f3f3;
  border: .5px solid #d0d6e1;
  overflow: hidden;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
  bottom: 0px;
  border-radius: 5px;
}

.fold-progress-bar {
  width: 100%;
  height: 8px;
  position: relative;
}

.fold-progress-fill {
  height: 100%;
  transition: width 0.3s ease;
  border-radius: 4px;
}
.fold-progress-fill .fold-progress-overlay {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Light overlay */
}

.measure-tooltip .ant-tooltip-inner {
  min-height: 18px;
  min-width: 500px;
}

.performance-indicator .ant-tooltip-inner {
  background-color: #FFFFFF;
  width: 150px;
  border-radius: 4px;
}

.performance-indicator .ant-tooltip-arrow-content::before {
  background-color: #fff;
  background: #fff;
  }