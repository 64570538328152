
 .care-team-assignee-modal .ant-modal-content .ant-modal-header {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.care-team-assignee-modal .ant-modal-content .ant-modal-body {
  padding: 0px !important;
}

.care-team-assignee-modal .ant-modal-content .ant-modal-footer {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding: 0px !important;
}


.care-team-assignee-modal .ant-modal-content {
  border-radius: 12px !important;
}