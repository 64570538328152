.consent-checkbox .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #e5e7eb !important; /* Colors.Custom.Gray300 */
  border-color: #e5e7eb !important;
}

.consent-checkbox
  .ant-checkbox-disabled.ant-checkbox-checked
  .ant-checkbox-inner::after {
  border-color: white !important;
}

.consent-radio .ant-radio-disabled .ant-radio-inner {
  border-color: #e5e7eb !important; /* Colors.Custom.Gray300 */
  background-color: white !important; /* Keep background white */
}

.consent-radio .ant-radio-disabled.ant-radio-checked .ant-radio-inner::after {
  background-color: #e5e7eb !important; /* Inner dot gray */
}
