.ant-picker,
.ant-picker-focused {
  border-radius: 8px;
  box-shadow: none;
  border-color: #e5e5e5;
  background-color: #f9fafb;
  font-size: 12;
  /* Commenting this as this is causing issue for multi line text */
  /* align-items: center; */
}

.ant-select {
  border-radius: 8px;
  box-shadow: none;
  border-color: #e5e5e5;
  background-color: #f9fafb;
  /* Commenting this as this is causing issue for multi line text */
  /* align-items: center; */
}

.ant-select-selector {
  border-radius: 8px;
  box-shadow: none;
  border-color: #e5e5e5;
  background-color: #f9fafb;
  height: 44px;
  /* Commenting this as this is causing issue for multi line text */
  /* align-items: center; */
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px;
  box-shadow: none;
  border-color: #e5e5e5;
  background-color: #fff;
  /* height: 44px; */
  /* Commenting this to remove hardcoded  height */
  /* Commenting this as this is causing issue for multi line text */
  /* align-items: center; */
}

.ant-picker-input input::placeholder {
  color: #8A94A8 !important;
}

.ant-picker-input > input {
  font-size: 14px;
  font-family: 'Inter_18pt-Regular';
  color: #3A485F;
}