.ant-collapse-content>.ant-collapse-content-box {
  padding: 0px;
}

.ant-collapse-content.ant-collapse-content-active {
  border-radius: 0 0 16px 16px !important;
}

.ant-collapse-header {
  background-color: #fff;
  border-radius: 16px !important;
}

.site-collapse-custom-collapse-panel .ant-collapse-header {
  background-color: #F9FAFB;
}

.site-collapse-custom-collapse {
  background-color: #F9FAFB !important;
}

.site-collapse-custom-collapse-panel {
  background-color: #F9FAFB;
}

.ant-collapse-extra {
  background-color: #fff;
}

.ant-collapse-content {
  border-top: 1px solid #e4e7ec;
  border-radius: 16px 0 0 0;
}

.ant-collapse {
  background-color: #fff;
  border: 1px solid #e4e7ec;
  border-radius: 16px;
}

.ant-collapse-custom {
  border: none;
  border-radius: 0px;
}

/* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgb(140, 33, 15) !important;
  font-weight: 500;
} */

.ant-tabs-ink-bar {
  position: absolute;
  /* background: rgb(140, 33, 15); */
  pointer-events: none;
}

.fhform-tab .ant-tabs .ant-tabs-ink-bar {
  background: rgb(130, 90, 199) !important;
}

.ant-collapse>.ant-collapse-item {
  border-bottom: none;
  border-radius: 16px;
}

.ql-toolbar.ql-snow {
  border-left: none !important;
  border-right: none !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.rich-text-quill .ql-container {
  height: 150px;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab {
  border-radius: 10px 10px 0 0;
  margin-right: 8px;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: transparent;
}

.ant-menu-item a:hover {
  color: transparent;
  text-decoration: none !important;
}

.ant-menu-item a {
  text-decoration: none !important;
}

.field-error .ant-select-selector,
.field-error.ant-input-number,
.field-error.ant-picker {
  border-color: var(--error-500) !important;
}

.field-error .ant-select-selector::placeholder,
.field-error.ant-input-number::placeholder,
.field-error.ant-picker::placeholder {
  border-color: var(--error-500) !important;
  color: #D0D5DD;
}

.intervention-type.ant-select-single .ant-select-selector .ant-select-selection-item {
  display: block !important;
  border-radius: 4px !important;
}

.intervention-type .ant-select-selector {
  border-radius: 4px !important;
}

::selection {
  color: #fff !important;
  background: #accdf588 !important;
}

.toastui-editor-defaultUI {
  border: none !important;
  border-top: 1px solid #dadde6 !important;
  border-radius: 0px !important;
  overflow-y: scroll;
}

.template-response-editor .toastui-editor-defaultUI {
  border: 0.5px solid #dadde6 !important; 
  border-radius: 4px !important;
}

.toastui-editor-defaultUI-toolbar button {
  border: none !important;
}

.toastui-editor-defaultUI-toolbar {
  background-color: #ffffff !important;

}

.toastui-editor-mode-switch {
  display: none !important;
}

.toastui-editor-defaultUI .ProseMirror {
  padding: 10px 14px !important;
}

.toastui-editor-toolbar-icons.more {
  background-position-x: 411px !important;
  width: 30px !important;
}

.toastui-editor-toolbar-icons.indent,
.toastui-editor-toolbar-icons.outdent,
.toastui-editor-toolbar-icons.table,
.toastui-editor-toolbar-icons.image,
.toastui-editor-toolbar-icons.code,
.toastui-editor-toolbar-icons.codeblock,
.toastui-editor-toolbar-icons.task-list {
  display: none !important;
}

.toastui-editor-toolbar-group>.toastui-editor-toolbar-icons {
  margin: 0;
  padding: 0;
  align-self: flex-end;
  justify-content: center;
  align-items: flex-end;
  align-self: flex-end;
  justify-items: center;
  justify-self: flex-end;
}

/* .toastui-editor-ww-container {
  margin-top: -10;
} */

.outdent+.toastui-editor-toolbar-divider {
  display: none !important;
}

.link+.toastui-editor-toolbar-divider {
  display: none !important;
}

.dashboard-calendar .rbc-month-view,
.dashboard-calendar .rbc-time-view {
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-top: none !important;
}

.dashboard-calendar .rbc-time-header-content>.rbc-row.rbc-row-resource {
  border-bottom: none !important;
}

.ant-picker-calendar-mini .ant-picker-panel {
  background-color: transparent;
}

.ant-multi-select .ant-select-selector {
  height: auto !important;
}

.uploadBoxContainer {
  box-shadow: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)';
}

.filterDrawer .ant-select-selection-overflow {
  height: 32px;
}

.filterDrawer .ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 30px;
}

.pami-search .ant-select-selector {
  border-radius: 8px !important;
  box-shadow: none;
}

.pami-search .ant-select-selection-item {
  align-items: center !important;
}

.eFax-select .ant-select-selection-item {
  align-items: center !important;
}

.pami-search .ant-select-selection-item div {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.pami-search span.ant-select-clear {
  width: 16px;
  height: 16px;
  top: 47%;
}


.calendar-practice-availability-view .rbc-month-view,
.calendar-practice-availability-view .rbc-time-view {
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-top: none !important;
}

.calendar-practice-availability-view .rbc-time-header-content>.rbc-row.rbc-row-resource {
  border-bottom: none !important;
}

.custom-style-extra-note-component {
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
}


/* STYLES FOR CALENDAR & DATE PICKER USED GLOBALLY */


.ant-radio-button-wrapper:first-child {
  border-radius: 8px 0 0 8px !important;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 8px 8px 0 !important;
}

.ant-radio-button-wrapper :focus {
  box-shadow: none !important;
}

.ant-radio-button-wrapper :first-child {
  border-radius: 8px 0 0 8px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #825ac7 !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #825ac7 !important;
  border-color: #825ac7 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #825ac7 !important;
}

.ant-radio-button-wrapper:hover {
  color: #825ac7 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #825ac7 !important;
  border-color: #825ac7 !important;
}

.ant-radio-button-checked>span {
  color: #825ac7 !important;
}

.ant-radio-button-wrapper-checked {
  border-color: #825ac7 !important;
}

.ant-picker-calendar-header {
  align-items: stretch;
}

.ant-picker-calendar-header :hover {
  border-color: #825ac7 !important;
}

.ant-picker-calendar-header>.ant-radio-group {
  display: flex;
  flex-direction: row;
}

.ant-picker-calendar-header>.ant-picker-calendar-mode-switch>* {
  height: 100% !important;
  display: flex;
  align-items: center;
}

.ant-picker-month-btn:hover {
  color: #825ac7 !important;
}

.ant-picker-year-btn:hover {
  color: #825ac7 !important;
}

.ant-picker-today-btn,
.ant-picker-now-btn {
  color: #825ac7 !important;
}

.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: #825ac7 !important;
  color: #fff !important;
}

.note-template .ant-popover-inner-content {
  padding: 0 !important;
}

.assignee-select .ant-popover-inner-content {
  padding: 0 !important;
}


/* GLOBAL STYLES FOR ANTD SELECTS & PICKERS  */
.ant-select :hover {
  border-color: #e5e5e5 !important;
}

.responsive-tag-render .ant-select :hover {
  border-color: white !important;
}

.ant-picker:hover {
  border-color: #e5e5e5 !important;
}

.ant-input-number-focused,
.ant-input-number:hover {
  border-color: #e5e5e5 !important;
}

.ant-picker {
  box-shadow: none !important;
}

.ant-input-number {
  box-shadow: none !important;
}

.ant-picker {
  background-color: #FFF !important;
}

.ant-select-item-option-selected {
  background-color: #eaebed !important;
  color: #000000 !important;
}

.custom-popover-styles .ant-popover-inner {
  background-color: #fff !important;
}

.custom-popover-styles .ant-popover-arrow-content {
  background-color: #fff !important;
  --antd-arrow-background-color: #fff !important;
}

.custom-option-white {
  background-color: white !important;
}

.careplan-timeline .ant-timeline-item-head-custom {
  padding: 0px;
}

.careplan-timeline .ant-timeline-item-tail {
  border-left: 0.5px solid #A7AFBE;
}

.careplan-timeline-last-item .ant-timeline-item-tail {
  border-left: 0px;
}

/* .timeline-collapse:nth-last-child() li.careplan-timeline.ant-timeline-item .ant-timeline-item-tail {
  display: none;
} */

.care-activity-collapse>.ant-collapse-header {
  border-radius: 0px !important;
}

.pressable {
  cursor: pointer;
}

.disable {
  pointer-events: none;
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.vstack {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bg-F9FAFB {
  background-color: #F9FAFB;
}

.bg-FFF6F2 {
  background-color: #FFF6F2;
}

.bg-F2F4F7 {
  background-color: #F2F4F7;
}

.w-full {
  width: 100%;
}

.w-0{
  width: 0px;
}

.flex-1 {
  flex: 1;
}

.flex-05 {
  flex: 0.5;
}

.disabled {
  pointer-events: none;
}

.p-4 {
  padding: 4px !important;
}

.justify-content-space-between {
  justify-content: space-between;
}


/* TAILWIND STYLES */

/* Flexbox */
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

/* Flex Properties */
.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-none {
  flex: none;
}

.w-100 {
  width: 100%;
}

.spacer {
  flex: 1;
}
/* Common styles for disabled states */
.adt-event-date-picker.ant-picker-disabled,
.adt-event-form-input-group .ant-input-group-compact > .ant-input-number-disabled,
.adt-event-form-input-group .ant-input-group-compact > .ant-select-disabled,
.adt-event-form-input-number.ant-input-number-disabled,
.adt-event-date-picker.ant-picker-disabled input,
.adt-event-form-input-number.ant-input-number-disabled input,
.adt-event-form-input-group-disabled .ant-input-group-compact > .ant-input-number,
.adt-event-form-input-group-disabled .ant-input-group-compact > .ant-select,
.adt-event-form-input.ant-input-disabled {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  cursor: not-allowed !important;
  color: rgba(0, 0, 0, 0.25) !important;
}

/* Common styles for disabled icons/text */
.adt-event-date-picker.ant-picker-disabled .ant-picker-suffix,
.adt-event-form-input-number.ant-input-number-disabled .ant-input-number-handler-wrap,
.adt-event-form-select.ant-select-disabled .ant-select-arrow,
.adt-event-form-input-group-disabled .ant-input-number-handler-wrap,
.adt-event-form-input-group-disabled .ant-select-arrow,
.adt-event-form-input-group-disabled .ant-input-group-compact > .ant-input-number input,
.adt-event-form-input-group-disabled .ant-input-group-compact > .ant-select .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed !important;
}

/* Common styles for hover/focus states */
[class*="adt-event"]:hover,
[class*="adt-event"]:focus,
[class*="adt-event-focused"],
.adt-event-date-picker.ant-picker-disabled:hover,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused,
.ant-input-group-compact > *:hover,
.ant-input-group-compact > *:focus {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

/* Common styles for focus states */
.ant-input-number:focus-visible,
.ant-input-group-compact > *:focus-visible,
.adt-event-form-input-group .ant-input-group-compact > *:focus-visible,
.adt-event-form-input:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

/* Common styles for input group spacing */
.adt-event-form-input-group .ant-input-group-compact > .ant-input-number-disabled {
  border-right-width: 1px !important;
}

/* Common styles for disabled radio states */
.ant-radio-group .ant-radio-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed !important;
}

.ant-radio-group .ant-radio-wrapper-disabled .ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  cursor: not-allowed !important;
}

.ant-radio-group .ant-radio-wrapper-disabled .ant-radio-disabled.ant-radio-checked .ant-radio-inner {
  border-color: #d9d9d9 !important;
}

.ant-radio-group .ant-radio-wrapper-disabled .ant-radio-disabled.ant-radio-checked .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #d9d9d9 !important;
}

.patient-status-select:hover .ant-select-selector{
  background-color: #f5f5f4;
}

.patient-status-select:hover .patient-status-select-icon {
  background-color: #fbfbf8;
}

.patient-status-select.ant-select-focused .patient-status-select-icon {
  background-color: #f9fafb !important;
}

.patient-status-select-icon {
  padding: 2px;
}

.fh-display-none {
  display: none;
}

.height100p {
  height: 100%;
}