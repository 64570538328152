.ant-select-selection-search-input {
  font-size: '12px';
}
.ant-select-selection-item,
.ant-select-selection-placeholder {
  font-size: 14px;
}

.ant-select-selector .ant-select-selection-placeholder {
  color: #8A94A8;
}