.editor-container .toastui-editor-toolbar {
  height: 0px !important;
}

.ant-select-selection-placeholder {
  color: #a7afbe;
}

.ant-select-selection-placeholder {
  color: #a7afbe !important;
}

.ant-drawer-content {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.email-recipient-custom-tag {
  span.anticon.anticon-close.ant-tag-close-icon svg {
    margin-bottom: 6px;
  }
  font-size: 14px;
  margin-right: 3px;
  background-color: #F6F7F8;
  border: none;
  margin: 2px auto;
  border-radius: 4px;
}

.discard-alert .ant-btn{
  border-radius: 4px !important;
}
.change-recipient {
  .ant-btn{
    border-radius: 4px !important;
  }
  .ant-modal-body {
    padding-top: 0;
    padding-bottom: 16px;
  }
  .ant-modal-footer {
    padding-top: 0;
    div {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

#email-drawer-container-v2.editor-container .toastui-editor-defaultUI {
  border: 0px !important;
}