.billingContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.billingHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 0.5px solid var(--Colors-Grey-150, #d0d6e1);
  background-color: var(--Colors-Monochrome-White, #fff);
}

.billingTitle {
  color: var(--Colors-Grey-300, #6f7a90);
  font: 500 14px/1.2 Inter, sans-serif;
}

.monthSelector {
  display: flex;
  align-items: center;
  gap: 8px;
}

.monthButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  cursor: pointer;
}

.currentMonth {
  min-width: 24px;
  padding: 4px 6px;
  border-radius: 4px;
  border: 0.5px solid var(--Colors-Grey-200, #8a94a8);
  background-color: var(--Colors-Monochrome-White, #fff);
  color: var(--Colors-Grey-300, #6f7a90);
  font: 500 12px/1.2 Inter, sans-serif;
}

.billingTable {
  width: 100%;
}

.billingTable :global(.ant-table-thead > tr > th) {
  background-color: var(--Colors-Monochrome-White, #fff);
  color: var(--Colors-Grey-300, #6f7a90);
  font: 500 14px/1.2 Inter, sans-serif;
}

.billingTable :global(.ant-table-tbody > tr > td) {
  font: 400 14px/1.2 Inter, sans-serif;
}

.providerType {
  display: block;
  color: var(--Colors-Grey-200, #8a94a8);
}

.claimBadge {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 400;
  text-align: center;
}

.pending {
  background-color: var(--Colors-Status-Light-Warning, #fef4e6);
  color: var(--Colors-Status-Dark-Warning, #f79009);
}

.accepted {
  background-color: var(--Colors-Status-Light-Success, #e8fdf3);
  color: var(--Colors-Status-Dark-Success, #12b76a);
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  cursor: pointer;
}

@media (max-width: 991px) {
  .billingContainer,
  .billingHeader,
  .billingTable {
    max-width: 100%;
  }
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  min-height: 300px;
}
