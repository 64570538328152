.vstack {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cancel-btn-text {
  margin-left: 1px;
  font-size: 14px;
  font-weight: 500;
  color: #825ac7;
  margin-top: 0px;
  margin-bottom: 0px;
}

.pressable {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
}

.copied-text {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 8px;
  font-size: 14px;
  color: #12b76a;
  font-family: Manrope-SemiBold;
  font-weight: 500;
}
