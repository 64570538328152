// Input box changes
$input-btn-focus-color: #f4f4f5 !default;
$input-border-color: #e5e5e5 !default;
$input-focus-border-color: #e5e5e5 !default;
$input-focus-bg: #f4f4f5 !default;
$input-box-shadow: inset 0 !default;
$input-btn-focus-box-shadow: 0 !default;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

span.flatpickr-day.selected,
span.flatpickr-day.startRange,
span.flatpickr-day.endRange,
span.flatpickr-day.selected.inRange,
span.flatpickr-day.startRange.inRange,
span.flatpickr-day.endRange.inRange,
span.flatpickr-day.selected:focus,
span.flatpickr-day.startRange:focus,
span.flatpickr-day.endRange:focus,
span.flatpickr-day.selected:hover,
span.flatpickr-day.startRange:hover,
span.flatpickr-day.endRange:hover,
span.flatpickr-day.selected.prevMonthDay,
span.flatpickr-day.startRange.prevMonthDay,
span.flatpickr-day.endRange.prevMonthDay,
span.flatpickr-day.selected.nextMonthDay,
span.flatpickr-day.startRange.nextMonthDay,
span.flatpickr-day.endRange.nextMonthDay {
  background: var(--primary-500);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: var(--primary-500);
}

.builder-component {
  padding: 0.5em 0.5em 0.5em;
}
.builder-component.active {
  box-shadow: 1px 1px 9px 0 rgb(0 0 0 / 4%);
  border: 1px solid var(--primary-200);
  padding: 0.5em 0.5em 0.5em;
  border-radius: 8px;
  // background-color: var(--primary-100);
}

.builder-component:hover {
  box-shadow: 1px 1px 9px 0 rgb(0 0 0 / 4%);
  border: 1px solid var(--primary-200);
  border-radius: 8px;
}

.builder-component:not(:hover) .component-btn-group {
  display: none;
}

.component-btn-group {
  position: absolute;
  right: 0;
  z-index: 1000;
  margin-top: -2px;
}

.component-delete-btn-group {
  margin-top: 8px;
  margin-right: 4px;
  border-width: 0px !important;
  border-radius: 0px !important;
}

button.btn.btn-block.builder-group-button {
  font-size: 0.7em;
  padding: 2px;
}

.formcomponents {
  flex: 4;
  // overflow-y: scroll;
  height: 77vh;
  padding-top: 0px;
  padding-bottom: 0px;
  max-width: 370px;
  position: relative;
  top: -76px;
}

.formarea {
  flex: 7;
  overflow-y: scroll;
  height: 72vh;
  padding: 20px;
}

.formedit_flex {
  flex: 4;
  max-width: 20vw;
  position: relative;
  top: -70px;
}

.formedit .component-edit-container {
  padding: 20px;
  // box-shadow: 1px 1px 9px 0 rgb(0 0 0 / 4%);
  border-left: 0.5px solid #dedede;
  margin-right: 10px;
  height: calc(100vh - 200px);
}

.formedit .edit-form {
  overflow-y: scroll;
  height: calc(100vh - 200px - 72px);
  margin-right: -10px;
}

.choices__list.choices__list--dropdown {
  height: 0px;
}

.choices__list.choices__list--dropdown.is-active {
  height: auto;
}

.formbuilder .formio-component-content,
.formbuilder .formio-component-datasource,
.formbuilder .formio-component-form,
.formbuilder .formio-component-hidden {
  border: none;
}

.formbuilder .formio-component-datasource,
.formbuilder .formio-component-form,
.formbuilder .formio-component-hidden {
  text-align: start;
}

.lead {
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
}

.edit-form .form-control {
  font-size: 0.8rem;
}

.card-header.form-builder-group-header {
  padding: 0.75rem 0.25rem;
  background-color: white;
}

.bp-systolic,
.bp-diastolic {
  width: 102px;
  float: left;
}
.bp-seperator {
  width: 28px;
  float: left;
  font-size: 21px;
  text-align: center;
  font-weight: 200;
  margin-top: 1px;
  margin-bottom: -16px;
}

.bp-unit {
  width: 64px;
  float: left;
  text-align: center;
  margin-top: 10px;
  margin-bottom: -7px;
}

.bp-data:after {
  content: '';
  display: table;
  clear: both;
}

.bmi-height,
.bmi-weight {
  width: 102px;
  float: left;
}
.bmi-seperator {
  width: 28px;
  float: left;
  font-size: 21px;
  text-align: center;
  font-weight: 200;
  margin-top: 1px;
  margin-bottom: -16px;
}

.bmi-height-label,
.bmi-weight-label,
.bmi-height-unit,
.bmi-weight-unit {
  width: 64px;
  float: left;
  text-align: center;
  margin-top: 10px;
  margin-bottom: -7px;
}

.bmi-height-unit,
.bmi-weight-unit {
  margin-left: -15px;
}

.bmi-height-label {
  margin-left: -10px;
}

.bmi-data:after {
  content: '';
  display: table;
  clear: both;
}

.formbuilder .formio-component-form {
  color: #1f2937;
}

.component-btn-group .component-settings-button,
.component-btn-group .component-duplicate-button {
  padding: 2px 2px;
  font-size: 12px;
  line-height: 1.2em;
  border-radius: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: none;
}

.component-btn-group .component-delete-button {
  border-radius: 0px;
  border-width: 0px;
  color: #667085;
  font-size: 17px;
}

.component-duplicate-button {
  margin-right: 5px;
  margin-top: 4px;
}

.bmi-value {
  font-size: 12px;
  margin-top: -8px;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--primary-500);
  border-color: var(--primary-500);
}

.btn-danger,
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: var(--danger-500);
  border-color: var(--danger-500);
}

.btn-default,
.btn-default.disabled,
.btn-default:disabled {
  color: var(--light-500);
  background-color: #fff;
  border-color: var(--light-500);
}

.btn-primary:hover {
  color: #fff;
  background-color: var(--primary-600);
  border-color: var(--primary-600);
}

.bg-primary {
  background-color: var(--primary-500) !important;
}
.bg-info {
  background-color: var(--info-500) !important;
}
.bg-success {
  background-color: var(--success-500) !important;
}
.bg-danger {
  background-color: var(--danger-500) !important;
}
.bg-warning {
  background-color: var(--warning-500) !important;
}

.formio-component .ql-toolbar.ql-snow,
.formio-component .ql-container.ql-snow {
  border-right: 1px solid var(--light-300) !important;
  border-left: 1px solid var(--light-300) !important;
}

.formio-component .ql-container.ql-snow {
  border-bottom: 1px solid var(--light-300) !important;
}

.formio-component .ql-editor {
  height: 100px;
}

.formio-component-button .btn {
  margin: auto;
  display: block;
  border-radius: 20px;
}

@media screen and (min-width: 479px) {
  .formio-component .form-column-2 {
    display: inline-block;
    width: 48%;
    margin-right: 16px;
  }

  .formio-component .form-column-3 {
    display: inline-block;
    width: 30%;
    margin-right: 16px;
  }

  .formio-component .form-column-parent {
    display: flex;
  }
}

.form-sidebar-item {
  align-items: center;
  margin-bottom: 8px;
}

.form-sidebar-item-text {
  font-size: 1.2em;
  margin-bottom: 4px;
  text-align: center;
}

.form-sidebar-item-icon {
  background-color: var(--primary-100);
  font-size: 1.4em;
  padding: 4px;
  border-radius: 20px;
  margin-top: 4px;
  margin-bottom: 4px;
  align-items: center;
  width: 30px;
  height: 30px;
}

.form-sidebar-container {
  background-color: var(--light-50);
}

.drag-container.formio-builder-form,
.drag-container.formio-builder-form:hover,
.panel-body > .drag-container.formio-builder-components,
.panel-body > .drag-container.formio-builder-components:hover,
.tab-pane > .drag-container.formio-builder-components,
.tab-pane > .drag-container.formio-builder-components:hover {
  height: 77vh;
}
.drag-container {
  height: 100%;
}

.pami-left-view .form-group {
  margin-bottom: 0px;
}

@media screen and (min-width: 768px) {
  .formio-component .col-md-9 {
    flex: 0 0 74%;
    max-width: 74%;
  }
}

// SOAP COMPONENT STYLING

// .formio-component-subjectiveComplaint , .formio-component-objectiveFindings , .formio-component-assessment , .formio-component-plan {
//   border-radius: 8px ;
//   border: 1px solid #D0D5DD;
//   gap:  8px;
//   padding: 8px 16px 16px 16px;
//   label {
//     font-weight: 700;
//     font-size: 16px;
//     margin-bottom: 6px;
//   }
//   .ql-toolbar {
//     border-radius: 8px 8px 0 0;
//     border-top: 1px solid #D0D5DD !important;
//     border-left: 1px solid #D0D5DD !important;
//     border-right: 1px solid #D0D5DD !important;
//     border-bottom: none !important;
//   }
//   .ql-container.ql-snow{
//     border-radius: 0 0 8px 8px;
//     border: 1px solid #D0D5DD !important;
//   }
// }
.custom-patient-reported-form-styles {
  .form-group{
    margin-bottom: 0 !important;
  }
}

.custom-form-styles {
  .formio-component-selectboxes.formio-component-label-hidden.required .form-check-label:before {
    content: " *" !important;
    position: static !important;
    color: red !important;
  }
  .form-group{
    margin-bottom: 0.7rem;
  }
  .formio-component-subjectiveComplaint,
  .formio-component-objectiveFindings,
  .formio-component-assessment,
  .formio-component-plan,
  .formio-component-textfield,
  .formio-component-textarea,
  .formio-component-email,
  .formio-component-customsurvey,
  .formio-component-customTable,
  .formio-component-file,
  .formio-component-checkbox,
  .formio-component-signature,
  .formio-component-phoneNumber,
  .formio-component-currency,
  .formio-component-number,
  .formio-component-date,
  .formio-component-radio,
  .formio-component-selectboxes,
  .formio-component-select,
  .formio-component-chiefComplaint,
  .formio-component-patientDemographics,
  .formio-component-consentAndPreferredCommunication,
  .formio-component-patientCommunication,
  .formio-component-patientAddress,
  .formio-component-emergencyContact,
  .formio-component-paragraph,
  .formio-component-panel,
  .formio-component-noteStatus,
  .formio-component-noteSubStatus {
    border-radius: 8px;
    margin-bottom: 0.7rem;
    border: 0.5px solid #D0D5DD;
    gap: 8px;
    padding: 8px 16px 16px 16px;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    background: #FFFFFF;
    // margin-left: 20px;
    // margin-right: 20px;
    label {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 6px;
    }

    .ql-toolbar {
      border-radius: 8px 8px 0 0;
      border-top: 1px solid #D0D5DD !important;
      border-left: 1px solid #D0D5DD !important;
      border-right: 1px solid #D0D5DD !important;
      border-bottom: none !important;
    }

    .ql-container.ql-snow {
      border-radius: 0 0 8px 8px;
      border: 1px solid #D0D5DD !important;
    }
  }

  .formio-component-panel {
    gap: 8px;
    padding: 0px !important;
    border:none;
    .card-body {
      padding: 1.5rem 0px !important;
    }
    .card-title {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 6px;
    }
    .border {
      border-radius: 8px;
      border: 1px solid #D0D5DD !important;
    }
  }
  .messaging-window {
    .formio-component-textarea {
      height: inherit;
    }
    .formio-component-textarea textarea.form-control {
      height: 600px;
      resize: none;
    }
  }
  .alert.alert-success  {
    display: none;
  }
}

.note-preview-styles {
  .formio-component:not(.formio-component-form) {
      label {
        width: 100% !important;
        padding-bottom: 8px !important;
        padding-top: 0px !important;
        font-weight: 800 !important;
        font-size: 18px !important;
        line-height: 1.5em;
        margin-bottom: 6px !important;
      }
      label:not(.control-label--hidden) {
        border-bottom: 1px solid black !important;
      }
    }
  .formio-component-subjectiveComplaint,
  .formio-component-objectiveFindings,
  .formio-component-assessment,
  .formio-component-plan,
  .formio-component-textfield,
  .formio-component-textarea,
  .formio-component-email,
  .formio-component-customsurvey,
  .formio-component-customTable,
  .formio-component-file,
  .formio-component-checkbox,
  .formio-component-signature,
  .formio-component-phoneNumber,
  .formio-component-currency,
  .formio-component-number,
  .formio-component-date,
  .formio-component-radio,
  .formio-component-selectboxes,
  .formio-component-select,
  .formio-component-chiefComplaint,
  .formio-component-patientDemographics,
  .formio-component-consentAndPreferredCommunication,
  .formio-component-patientCommunication,
  .formio-component-patientAddress,
  .formio-component-emergencyContact,
  .formio-component-paragraph,
  .formio-component-panel{
    padding: 0px !important;
    border: 0px !important;
    box-shadow: none !important;
    background: transparent !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    gap:0px !important;
    margin-bottom: 0px !important;
    margin: 8px 0px 8px 0px !important;
  }
    .formio-component-panel {
      .border {
        border: 1px solid #000 !important;
      }

      .card-header {
        padding: 0px !important;
        border: 0px !important;
        box-shadow: none !important;
        background: transparent !important;
      }
      .card-body {
        padding: 16px 0px !important;
      }
      .card {
        padding: 8px !important;
      }
      .card-title {
        font-weight: 800;
        font-size: 18px;
        line-height: 1.5em;
      }
    }
  .formio-component-chiefComplaint,
  .formio-component-patientDemographics,
  .formio-component-consentAndPreferredCommunication,
  .formio-component-patientCommunication,
  .formio-component-patientAddress,
  .formio-component-emergencyContact {
    label {
      display: none;
    }
  }
  .formio-component-signature {
    img {
      border: none !important;
    }
  }
}

.signature-pad-canvas {
  border-radius: 4px;
  box-shadow: 0 0 5px rgb(0 0 0 / 2%) inset;
  border: 1px solid #e5e5e5;
}

.formio-error-wrapper {
  color: rgba(0,0,0,.85);
  background-color: #fff;
  padding: 0px;
}

// global change in style for required checkbox w/o label
.formio-component-selectboxes.formio-component-label-hidden.required .form-check-label:before {
  content: " *" !important;
  position: static !important;
  color: red !important;
}

.formio-component .col-form-label {
  font-size: 14px;
}


.care-plan-styles {
  .card {
    background-color: rgb(246, 247, 248) !important;
    border: 0px !important;
    border-radius: 12px !important;
  }
  .card-header {
    font-weight: 700;
    background-color: transparent !important;
    border-bottom: 0px !important;
    font-size: medium !important;
    padding: 0.5rem 1rem 0.5rem 1rem !important;
    align-items: center;
   }
  .card-body {
    padding: 0.5rem 1rem 0 1rem !important;
  }

}
