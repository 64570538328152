.custom-drawer-styles {
    .ant-drawer-body {
        padding: 0 !important;
    }

    .ant-drawer-header {
        padding-bottom: 0px !important;
    }
}

.custom-task-drawer-web {
   .ant-drawer-body {
        padding: 0 !important;
    }

    .ant-drawer-header {
      padding-bottom: 10px !important;
      border-bottom: 1px solid #D0D6E1;
    }
}

.custom-task-drawer-sidecar {
   .ant-drawer-header {
     padding-bottom: 10px !important;
     border-bottom: 1px solid #D0D6E1 !important;
   }
}