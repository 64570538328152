.custom-timeline{
  width:100%;
  .ant-timeline-item{
    height:100%;
    width: 100%;
    padding-bottom: 0;
    .ant-timeline-item-tail {
      top: 0px;
      height: 100% !important;
  }
    .ant-timeline-item-content{
      top:0;
    }
    position: relative;
    .ant-timeline-item-head-custom,.ant-timelin-item-head{
        background: transparent;
        top:0;
    }
  }
}
