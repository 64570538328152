.appointment-notes {
  min-height: 150px;
  border-left: 1px solid var(--light-200);
  border-right: 1px solid var(--light-200);
  border-bottom: 1px solid var(--light-200);
  border-radius: 8px !important;
  overflow: hidden;
}

.appointment-notes .ql-toolbar {
  border-color: var(--light-200) !important;
}

.appointment-notes .ql-container > .ql-editor.ql-blank::before{
  font-size: 14px;
  font-style: normal;
  color: #8a94a8;
  }

.collapse-panel-custom > .ant-collapse-header > .ant-collapse-header-text {
  width: 100%;
  flex: 1;
}

.collapse-panel-custom-cursor-pointer > .ant-collapse-header {
  cursor: pointer !important;
}

.collapse-panel-custom-cursor-default > .ant-collapse-header {
  cursor: default !important;
}
  
.appointment-booking-drawer .ant-drawer-body {
  padding-top: 0px !important;
}


.custom-input-number .ant-input-number-group {
  border-radius: 4px !important;
  border: 0.5px solid #8a94a8 !important;
}

.custom-input-number .ant-input-number {
  border: 0px !important;
  border-radius: 4px !important;
}

.custom-input-number .ant-input-number-group-addon {
  background-color: transparent !important;
  border: 0px !important;
}

.custom-input-number input {
  color: #8a94a8 !important;
}

.custom-date-picker {
  height: 32px !important;
  border-radius: 4px !important; 
  border: 0.5px solid #8a94a8 !important;
}

.custom-date-picker .ant-picker-suffix {
  color: #8a94a8 !important;  
}

label {
  margin-bottom: 0px !important;
}

.custom-collapse.ant-collapse .ant-collapse-header {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-radius: 0px !important;
  padding-left: 0px !important;
}

.custom-segmented.ant-segmented {
  width: fit-content !important;
}

.custom-switch.ant-switch {
  border-radius: 4px !important;
}

.custom-switch.ant-switch .ant-switch-handle::before {
  border-radius: 4px !important;
}

.custom-text-input {
  border-radius: 4px !important;
  border: 0.5px solid #8a94a8 !important;
  font-size: 14px !important;
  color: #8a94a8;
}