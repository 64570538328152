.email-msgView div,
.email-msgView p,
.email-msgView span {
  width: 100% !important;
}
.email-msgView{
  overflow-x: scroll;
}

.email-msgView a{
  word-wrap: break-word;
}

.email-msgView img{
  max-width: 100%;
}

label.copy-checkbox span span {
  width: 20px;
  height: 20px;
  border: 1px solid #98A2B3;
  border-radius: 4px;
}
label.copy-checkbox  .ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent !important;
    border-color: #825ac7 !important;
}
label.copy-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #825ac7 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  left:4px
}
