.completionArea {
  align-items: center;
  background: var(--Colors-Monochrome-White, #fff);
  display: flex;
  max-width: 968px;
  padding: var(--Height, 120px) 200px;
  flex-direction: column;
  overflow: hidden;
  font-family: Inter, sans-serif;
  line-height: 1.2;
  gap: var(--52, 52px);
}

@media (max-width: 991px) {
  .completionArea {
    padding: 100px 20px;
  }
}

.headerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 991px) {
  .headerContent {
    max-width: 100%;
  }
}

.logo {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100px;
  max-width: 100%;
}

.title {
  color: var(--Colors-Grey-400, #3a485f);
  font-size: 24px;
  font-weight: 500;
  margin-top: 8px;
}

@media (max-width: 991px) {
  .title {
    max-width: 100%;
  }
}

.subtitle {
  color: var(--Colors-Grey-300, #6f7a90);
  font-size: 16px;
  font-weight: 400;
  margin-top: 8px;
}

.statusContainer {
  align-items: stretch;
  border-radius: var(--12, 12px);
  border: 0.5px solid var(--Colors-Grey-150, #d0d6e1);
  display: flex;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  color: var(--Colors-Grey-400, #3a485f);
  font-weight: 500;
  text-align: center;
  line-height: 17px;

}

@media (max-width: 991px) {
  .statusContainer {
    margin-top: 40px;
    flex-direction: column;
  }
}

.statusItem {
  justify-content: center;
  display: flex;
  align-items: center;
  padding: var(--24, 24px);
  flex-direction: column;
  flex: 1;
  flex-basis: 0%;
}

.statusItem:not(:last-child) {
  border-right: 0.5px solid var(--Colors-Grey-150, #d0d6e1);
}

@media (max-width: 991px) {
  .statusItem {
    padding: 20px;
  }

  .statusItem:not(:last-child) {
    border-right: none;
    border-bottom: 0.5px solid var(--Colors-Grey-150, #d0d6e1);
  }
}

.statusIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: center;
}

.statusText {
  margin-top: 10px;
}

.actionArea {
  display: flex;
  margin-top: 52px;
  align-items: start;
  gap: 16px;
  font-size: 14px;
  color: var(--Colors-Grey-300, #6f7a90);
  font-weight: 500;
}

@media (max-width: 991px) {
  .actionArea {
    margin-top: 40px;
  }
}

.actionButton {
  min-width: var(--32, 32px);
  justify-content: center;
  align-items: center;
  border-radius: var(--4, 4px);
  border: 0.5px solid var(--Colors-Grey-200, #8a94a8);
  background: var(--Colors-Monochrome-White, #fff);
  display: flex;
  height: var(--32, 32px);
  padding: var(--8, 8px);
  gap: var(--8, 8px);
}

.buttonContent {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  margin: auto 0;
}

.buttonIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  align-self: stretch;
  margin: auto 0;
}

.buttonText {
  align-self: stretch;
  margin: auto 0;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
