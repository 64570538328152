.conversation-messaging > .ql-container.ql-snow {
  height: 180px;
}

.conversation-messaging > .ql-toolbar.ql-snow {
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}

.rich-text-editor {
  min-height: 150px;
  border-top: 1px solid var(--light-200);
  border-left: 1px solid var(--light-200);
  border-right: 1px solid var(--light-200);
  border-bottom: 1px solid var(--light-200);
  border-radius: 4px;
}

.rich-text-editor.read-only .ql-toolbar {
  display: none;
}

.rich-text-editor .ql-editor {
  max-height: 250px;
  font-size: 14px;
}

.rich-text-editor.hideBottomBorder {
    border-bottom: 0px solid var(--light-200);
}

.formio-component .ql-editor {
  max-height: 100px;
}

.formio-component .conversation-messaging > .ql-container.ql-snow {
  height: 100px;
}

.formio-component .rich-text-editor {
  min-height: 150px;
  border-top: 0.5px solid var(--light-200);
  border-left: 0.5px solid var(--light-200);
  border-right: 0.5px solid var(--light-200);
  border-bottom: 0px;
  border-radius: 4px;
}

.full-width-rich-text-editor {
  width: 100%;
}
