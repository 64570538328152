@media print {
  .col-md-3 {
    width: 32%;
    float: left;
  }
  .col-md-6 {
    width: 50%;
    float: left;
  }
  .col-md-9 {
    width: 74%;
    float: left;
  }

  .page-break {
    page-break-inside: avoid !important;
    break-inside: avoid !important;
    clear: both !important;
    page-break-before: auto !important;
    page-break-after: auto !important;
    break-before: auto !important;
    break-after: auto !important;
  }
  .page-header-space {
    // page-break-inside: avoid !important;
    // break-inside: avoid !important;
    // clear: both !important;
    // page-break-before: avoid !important;
    page-break-after: avoid !important;
    // break-before: avoid !important;
    break-after: avoid !important;
  }
  @page {
    margin: 10mm 10mm 10mm 10mm;
  }
}
