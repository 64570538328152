.rule-drawer-custom-input:hover,
.rule-drawer-custom-input > .ant-input:hover {
  border-color: #8C5AE2 !important;
}

.rule-drawer-custom-input:focus,
.rule-drawer-custom-input > .ant-input:focus {
  border-color: #8C5AE2 !important;
  box-shadow: none !important;
}

.rule-drawer-custom-input > .ant-input {
  color: #8A94A8 !important;
}