.patient-status-select-option .ant-select-item-option-content{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.patient-status-select-tooltip-icon {
  display: none;
  margin-left: 4px;
  cursor: pointer;
}

.patient-status-select-option:hover .patient-status-select-tooltip-icon {
  display: block;
}

.patient-status-select-popup.ant-select-dropdown {
  width: auto !important;
  min-width: 220px !important;
}


.patient-status-select:hover .ant-select-selector {
  border: 0px !important;
}

.patient-status-select .ant-select-selector {
  border: 0px !important;
}