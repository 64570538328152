.customMsgFotterTabs .ant-tabs-nav-list .ant-tabs-tab {
  padding: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: 0px;
  border-radius: 20px;
  margin-bottom: 8px;
  margin-top: 8px;
}
.customMsgFotterTabs .ant-tabs-nav {
  padding-left: 16px !important;
}
.customMsgFotterTabs .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  color: #667085;
}
.customMsgFotterTabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #825ac7;
}
.customMsgFotterTabs
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}
.ant-tabs-ink-bar {
  background: #825ac7 !important;
}
.messagewindow.internalMessage .ant-tabs-ink-bar {
  background: #f37232 !important;
}
.reply .ant-tabs-ink-bar {
  background: #825ac7 !important;
}
.internal-reply .ant-tabs-ink-bar  {
  background: #F37232 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #825ac7 !important;
  font-weight: 500;
}
.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  color: #667085;
  font-weight: 500;
}

.customMsgFotterTabs .ant-tabs-nav-list .ant-tabs-ink-bar-animated {
  display: none;
}

.customTabPane {
  background-color: pink;
}
.ant-tabs-nav {
  padding-left: 12px !important;
}
.customAntTab .ant-tabs-nav {
  padding-left: 0px !important;
}

.customMsgFotterTabs .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  display: none;
}

.convert-patient-custom-switch.checked {
  background-color: #F37232!important;
}

.ant-checkbox + span {
  margin-right: 0!important;
}