.blurDiv {
    filter: blur(5px);
}


@keyframes shake {
    0% { transform: translate(0, 0); }
    20% { transform: translate(-5px, 0); }
    40% { transform: translate(5px, 0); }
    60% { transform: translate(-5px, 0); }
    80% { transform: translate(5px, 0); }
    100% { transform: translate(0, 0); }
  }
  
  .shake {
    animation: shake 0.5s ease-in-out; /* Adjust the duration and timing function as needed */
  }


.not-allowed {
cursor: not-allowed
}

.comparison-operator-select .ant-select-selector {
  height: 40px !important;
  display: flex;
  align-items: center;
}