.common-collapse .ant-collapse-header {
    background-color: #fff;
    min-height: 75px;
    align-items: center !important;
    /* border-bottom: 1px solid  #E4E7EC; */
    padding: 0;
    /* border-radius: 8px 8px 0px 0px !important ; */
    /* border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important; */
    border-radius: 8px;
  }
  .common-collapse .ant-collapse {
    border-radius: 8px;
  }


  .common-collapse .alerts-panel-container .ant-collapse-header {
    min-height: auto;
  }