.custom-progress-styles {
    .ant-progress-bg {
        height: 10px !important;
        border-radius: 0px !important;
    }
    .ant-progress-inner {
        border-radius: 0px !important;
        height: 10px !important;
    }
    .ant-progress-text {
        font-size: 12px !important;
        color: #667085 !important;
    }
}