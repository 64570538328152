.msg-attachment {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 0px;
}

.msg-attachment .ant-upload-select-text {
  margin-top: 0px;
}

.messagewindow .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-layout-content.messagewindow-editor .toastui-editor-defaultUI-toolbar {
  border: 0px;
}
.ant-layout-content.messagewindow-editor .toastui-editor-ww-container,
.ant-layout-content.messagewindow-editor .toastui-editor-defaultUI-toolbar {
  /* background-color: #fcfdfd !important; */
  background-color: #fff !important;
  font-size: 15px;
  justify-content: flex-end;
}
.ant-layout-content.messagewindow-editor .ProseMirror.toastui-editor-contents {
  border-radius: 4px;
  padding: 0 !important;
  padding: 10px 16px !important ;
  height: auto !important;
  max-height: 132px;
  border: 1px solid #d0d5dd !important;
  /* margin-left: 8px !important; */
  /* //margin-right: 16px !important; */
  /* background-color: #fcfcfd !important; */
  background-color: #fff !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-size: 15px;
}
.ant-layout-content.messagewindow-editor .toastui-editor-defaultUI-toolbar {
  border-bottom: none;
  margin-top: -5px;
}

.ant-layout-content.messagewindow-editor .toastui-editor-defaultUI {
  border-top: 0 !important;
}
.ant-layout-content.messagewindow-editor .toastui-editor-popup.toastui-editor-popup-add-link {
  position: fixed;
  margin-left: 200px;
  margin-top: 25%;
}

.ant-layout-content.messagewindow-editors .ProseMirror.toastui-editor-contents {
  border-radius: 6px;
  padding: 0 !important;
  padding: 10px 16px !important ;
  border: 1px solid #d0d5dd !important;
  background-color: #fcfcfd !important;
  font-size: 15px;
}

.editor-inactive
{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(299,299,299,0.5);

  /* Add the blur effect */
  filter: blur(2px);
  -webkit-filter: blur(2px);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 194px; */
}

.ant-layout-content.messagewindow-editor-toolbar-without-collapse .toastui-editor-defaultUI-toolbar {
  border-bottom: none;
  margin-top: -5px;
  margin-right: 8%;
}

.ant-layout-content.messagewindow-editor-toolbar-internal-collapse .toastui-editor-defaultUI-toolbar {
  border-bottom: none;
  margin-top: -5px;
  margin-right: 7%;
}

.ant-layout-content.messagewindow-editor-toolbar-other-collapse .toastui-editor-defaultUI-toolbar {
  border-bottom: none;
  margin-top: -5px;
  margin-right: 17%;
}

.messagewindow-editor.internalMessage-editor .ProseMirror.toastui-editor-contents {
  border: 1px solid #f37232 !important;
  background-color: #FFFDFA !important;
}

#toastui-editor-toolbar-height .toastui-editor-toolbar {
  height: 0px;
}

.on-enter-action-popup {
  position: absolute;
  top: auto;
  bottom: 2.5% !important;
  left: 38.5% !important;
}

.on-enter-action-popup-instant-chat-view {
  position: absolute;
  top: auto;
  bottom: 6% !important;
  left: 52.25% !important;
}

.on-enter-action-popup .ant-modal-content, .on-enter-action-popup-instant-chat-view .ant-modal-content {
  width: 225px;
  border-radius: 10px !important;
}

.on-enter-action-popup .ant-modal-header, .on-enter-action-popup-instant-chat-view .ant-modal-header {
  padding-bottom: 10px !important;
  margin-bottom: 0px !important;
  padding-left: 16px !important;
}

.on-enter-action-popup .ant-modal-body, .on-enter-action-popup-instant-chat-view .ant-modal-body {
  padding: 0px 0px 15px 0px !important;
}

.reply-type-switch-internal{
  align-self: center;
  border-radius: 4px;
  background-color: #F37232;
}

.reply-type-switch-reply{
  align-self: center;
  border-radius: 4px;
  background-color: #E4E7EC;
}

.message-footer-radio-group{
  margin-top: 0px;
}

.message-footer-radio-group-item{
  padding: 5px 0px 5px 16px;
}

.send-button {
  cursor: pointer;
}

.send-button-flex-07 {
  flex: 0.7;
}

.send-button-flex-05 {
  flex: 0.5;
}

.schedule-button {
  height: 20px;
  width: 20px;
  margin-top: 4px;
  cursor: pointer;
}
