.day-op-events-ribbon .event-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 4px;
}

.day-op-events-ribbon .event-container-controller.invisible {
  visibility: hidden;
}

.day-op-events-ribbon .event-container-badge {
  background-color: #F47A3E;
  border-radius: 4px;
  border: 0.5px solid #E9ECF1;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  min-width: 16px;
  margin-left: 4px;
}

.day-op-events-ribbon .event-container.newNotification {
  background-color: #E7F0FE;
}

.day-op-events-ribbon .event-container.bg-white {
  background-color: #ffffff;
}

.day-op-events-ribbon .event-container.bg-highlight {
  background-color: #E7F0FE;
}

.day-op-events-ribbon .event-container.newNotification .event-container-controller .event-container-badge {
  background-color: #F47A3E;
}

.day-op-events-ribbon .event-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.day-op-events-ribbon .event-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.day-op-events-ribbon .event-item-controlls {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}


/* Animation for new notification */

.day-op-events-ribbon .event-container.newNotification,
.day-op-events-ribbon .event-container.newNotification1 {
  position: relative;
  overflow: hidden;
  animation: fadeToLight 800ms linear 0ms forwards, fadeToWhite 400ms linear 800ms forwards;
}

@keyframes fadeToLight {
  0% {
    background: #a5c0f0;
  }

  100% {
    background: #E7F0FE;
  }
}

@keyframes fadeToWhite {
  0% {
    background: #c2d4f1;
  }

  100% {
    background: #ffffff;
  }
}

.day-op-events-ribbon .event-container.newNotification::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100px;
  width: 100px;
  height: 100%;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.8), transparent);
  animation: lightWave 1200ms cubic-bezier(0.4, 0, 0.2, 1) 500ms forwards;
}

@keyframes lightWave {
  0% {
    left: -100px;
    transform: skewX(-20deg);
  }

  100% {
    left: calc(100% + 100px);
    transform: skewX(-20deg);
  }
}