.custom-tag {
  span.anticon.anticon-close.ant-tag-close-icon svg {
    margin-bottom: 4px;
  }
  font-size: 14px;
  margin-right: 3px;
  background-color: #F2F4F7;
  border-radius: 12px;
  margin: 2px auto;
}

.appointment-user-select .ant-select-item-option-state {
  padding-top: 12px;
}


.custom-user-box .ant-select-selector,
.custom-user-box .ant-select-selector:hover,
.custom-user-box.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff !important;
  min-height: 32px !important;
  border-radius: 4px !important;
  border: 0.5px solid #8a94a8 !important;
  color:#8a94a8;
}


