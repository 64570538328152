.custom-confirmation-modal .ant-modal-header {
  padding: 20px 20px 0 20px !important;
}

.custom-confirmation-modal .ant-modal-body {
  padding: 0 20px 0 20px !important;
}

.custom-confirmation-modal .ant-modal-footer {
  padding: 0 20px 20px 20px !important;
}

.custom-confirmation-modal .ant-modal-content {
  border-radius: 12px !important;
  border: 0.5px solid #E9ECF1;
}
