.adt-event-form-input:hover {
  border-color: #8c5ae2 !important;
}

.adt-event-form-input:focus {
  border-color: #8c5ae2 !important;
  box-shadow: none !important;
}
.adt-event-date-picker.ant-picker:hover {
  border-color: #8c5ae2 !important;
}

.adt-event-date-picker.ant-picker-focused {
  border-color: #8c5ae2 !important;
}

.adt-event-date-picker input {
  color: #6B7280 !important;
}

/* For placeholder text */
.adt-event-date-picker input::placeholder {
  color: #D0D5DD !important
}

/* Selected tags styling */
.custom-select-tags .ant-select-selection-item {
  background-color: #f0f0f0 !important;
  border-radius: 4px !important;
  padding: 2px 6px !important;
  color: #666666 !important; /* Colors.FoldPixel.GRAY250 */
  border: none !important;
}

/* Hover state for dropdown options */
.custom-select-tags .ant-select-dropdown .ant-select-item-option:hover,
.custom-select-tags .ant-select-dropdown .ant-select-item-option-active {
  color: #9333ea !important;
}

/* Selected state in dropdown */
.custom-select-tags .ant-select-dropdown .ant-select-item-option-selected {
  color: #9333ea !important;
}

/* Focus state of the select box */
.custom-select-tags.ant-select:focus-within .ant-select-selector,
.custom-select-tags.ant-select.ant-select-focused .ant-select-selector {
  border-color: #9333ea !important;
}

/* Hover state of the select box */
.custom-select-tags.ant-select:not(.ant-select-disabled):hover
  .ant-select-selector {
  border-color: #9333ea !important;
}

.ant-select-focused .ant-select-selector,
.custom-select-tags .ant-select-selector:focus {
  background-color: white !important;
}

.custom-select-tags .ant-select-selector:hover {
  background-color: white !important;
}

.adt-event-form-input[type='number']::-webkit-inner-spin-button,
.adt-event-form-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.adt-event-form-input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.adt-event-form-input-group {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.adt-event-form-input-group:hover {
  border-color: #8c5ae2 !important;
}

/* Add focus state for the group when any child is focused */
.adt-event-form-input-group:has(:focus-within) {
  border-color: #8c5ae2 !important;
}

.adt-event-form-input-number {
  /* border: none !important; */
  border: black;
  border-radius: 0 !important;
  box-shadow: none !important;
  outline: none !important;
}

.adt-event-form-input-number .ant-input-number-handler-wrap {
  display: none !important;
}

.adt-event-form-select {
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  outline: none !important;
}

.adt-event-form-select .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

/* Focus states */
.adt-event-form-input-number:focus,
.adt-event-form-input-number-focused,
.adt-event-form-select .ant-select-focused .ant-select-selector,
.adt-event-form-select.ant-select-focused .ant-select-selector {
  /* border: none !important; */
  box-shadow: none !important;
  outline: none !important;
}

/* Hover states */
/* .adt-event-form-input-number:hover,
.adt-event-form-select:hover .ant-select-selector {
  border: none !important;
} */

/* Open state for select */
.adt-event-form-select.ant-select-open .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}

