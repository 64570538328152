.ant-upload.ant-upload-select-picture-card {
    width: 294px !important;
    height: 200px !important;
}

.ant-upload-list-picture-card-container {
    width: 294px !important;
    height: 200px !important;
}

.ant-upload-list-item-thumbnail img {
    object-fit: cover !important;
  }